import ReactLoading from "react-loading";
import "./big-loading.css";

export default function BigLoading() {
    return(
        <ReactLoading
            className="search--big-loading"
            type={"spin"}
            color={"#DCC4AE"}
            height={100}
            width={100}
        />
    )
}