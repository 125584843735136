import axios from "axios";
import env from '../../../../env/env';

/**
 * This function is intended to be used as a React effect to fetch all the data for composers
 * in order to display them in the querybuilder.
 */
export default function fetchComposers(setConfig) {
    let composers;
    let listValues = [];
    axios.post(env.ELASTIC_API_URL + '/orchard_index/_search/', {
        "size": 0,
        "aggs": {
            "composers": {
                "terms": {
                    "field": "composer_exact",
                    "size": 100,
                }
            }
        }})
        .then((res) => {

            composers = res.data.aggregations.composers.buckets;

            // sort alphabetically
            composers.sort((a, b) => a.key.localeCompare(b.key));

            for (let composer of composers) {
                listValues.push({value: composer.key, title: composer.key, path: []});
            }

            setConfig(prevConfig => {
                return {
                    ...prevConfig,
                    fields: {
                        ...prevConfig.fields,
                            composer_exact: {
                                ...prevConfig.fields.composer_exact,
                                fieldSettings: {
                                    ...prevConfig.fields.composer_exact.fieldSettings,
                                    listValues
                                }
                            }
                    }
                }
            })
        })
        .catch((err) => console.log(err));
}