
import React, { useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import env from '../../env/env';
import "./Pieces.css";
import Spinner from "../core/spinner/Spinner";
import { Table, TableBody, TableCell, TableHead, TableRow, 
         TableContainer, Paper, Button, Stack, Typography} from "@mui/material";


export default function Pieces() {

    const [pieces, setPieces] = useState([])
    const [done, setDone] = useState(undefined);

    const navigate = useNavigate();

    useEffect(() => {
        axios.get(env.BACKEND_API_URL + '/api/piece/').then((res) => {
            setPieces(res.data.results);
            setDone(true);      
        });    
    }, []);

    function handlePieceClick(id) {
        navigate(`/pieces/${id}/`);
    }

    function handleMovementClick(id) {      
        navigate(`/movements/${id}/`);
    }

    return(
        <div className="pieces--content">
            <header className="pieces--header">
                <h1>Pieces</h1>
            </header>
            { !done ? (
                <Spinner />  
            ):(
                <div className="pieces--main">
                    <TableContainer component={Paper} sx={{width: "90%"}}>
                        <Table>
                            <TableHead>
                                <TableCell sx={{fontSize: '24px', fontWeight: "600"}}>Pieces</TableCell>
                                <TableCell align="center" sx={{ fontSize: '24px', fontWeight: "600"}}>Movements</TableCell>
                            </TableHead>
                            <TableBody>
                                {
                                    pieces.map((piece, i) => {
                                        return(
                                            <TableRow key={i}>
                                                <TableCell style={{verticalAlign:"top"}}>
                                                    <Button
                                                        onClick={e => handlePieceClick(piece.id)}
                                                    >
                                                        <Typography variant="subtitle1" align="left"><b>{piece.full_title}</b></Typography>
                                                    </Button>
                                                </TableCell>
                                                <TableCell>
                                                    <Stack spacing={1} align="center">
                                                        {
                                                            piece.movements.map((movement, j) => {
                                                                return(
                                                                    <Button key={j} variant="outlined" onClick={e => handleMovementClick(movement.id)}>
                                                                        <b>{movement.full_title}</b>
                                                                    </Button>
                                                                )
                                                            })
                                                        }
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}          
        </div>
    )
}