import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import env from '../../env/env';
import "./Composers.css";
import Spinner from "../core/spinner/Spinner";
import { Table, TableBody, TableCell, TableHead, TableRow, 
         TableContainer, Paper, Button, Stack, Typography } from "@mui/material";

export default function Composers() {

    const [composers, setComposers] = useState([]);
    const [done, setDone] = useState(undefined);
    const navigate = useNavigate();

    useEffect(() => {       
        axios.get(env.BACKEND_API_URL + '/api/composer/').then((res) => {
            setComposers(res.data.results);
            setDone(true);         
        })      
    }, []);

    function handlePieceClick(id) {
        navigate(`/pieces/${id}/`)
    }

    return (
        <div className="composers--content">
            <header className="composers--header">
                <h1>Composers</h1>
            </header>
            { !done ? (
                <Spinner />
            ):(
                <div className="composers--main">       
                    <TableContainer component={Paper} sx={{width: "90%"}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" sx={{ fontSize: '24px', fontWeight: "600"}}>Composer</TableCell>
                                    <TableCell align="center" sx={{ fontSize: '24px', fontWeight: "600"}}>Birth Country</TableCell>
                                    <TableCell align="center" sx={{ fontSize: '24px', fontWeight: "600"}}>Pieces</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    composers.map((composer, i) => {
                                        return (
                                            <TableRow key={i}>
                                                <TableCell>
                                                    <Typography 
                                                        variant="h6"
                                                        align="left"
                                                    >
                                                        {composer.full_name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography 
                                                        variant="body1"
                                                        align="center"
                                                    >   
                                                        {composer.birth_country}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Stack spacing={1} align="center">
                                                        {
                                                            composer.pieces.map((piece, j) => {
                                                                return(
                                                                    <div key={j} >
                                                                        <Button 
                                                                            variant="text"
                                                                            onClick={e => handlePieceClick(piece.id)}
                                                                        >
                                                                            <b>{piece.title}</b>
                                                                        </Button>
                                                                    </div>  
                                                                )
                                                            })
                                                        }
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    
                </div>
            )}
        </div>       
    )
}