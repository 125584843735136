import axios from "axios";
import env from '../../../../env/env';

/**
 * This function is intended to be used as a React effect to fetch all the data for pieces
 * in order to display them in the querybuilder.
 */
export default function fetchPieces(setConfig) {
    let pieces;
    let listValues = [];
    axios.post(env.ELASTIC_API_URL + '/orchard_index/_search/', {
        "size": 0,
        "aggs": {
            "pieces": {
                "terms": {
                    "field": "piece_exact",
                    "size": 100,
                }
            }
        }})
        .then((res) => {

            pieces = res.data.aggregations.pieces.buckets;

            // sort alphabetically
            pieces.sort((a, b) => a.key.localeCompare(b.key));

            for (let piece of pieces) {
                listValues.push({value: piece.key, title: piece.key, path: []});
            }

            setConfig(prevConfig => {
                return {
                    ...prevConfig,
                    fields: {
                        ...prevConfig.fields,
                            piece_exact: {
                                ...prevConfig.fields.piece_exact,
                                fieldSettings: {
                                    ...prevConfig.fields.piece_exact.fieldSettings,
                                    listValues
                                }
                            }
                    }
                }
            })
        })
        .catch((err) => console.log(err));
}