import React, { useEffect, useState} from "react";
import axios from "axios";
import env from '../../../env/env';
import Spinner from "../../core/spinner/Spinner";
import "./PieceDetail.css";
import { useNavigate, useParams } from "react-router-dom";
import { Paper, Typography, Stack, Button, Grid } from '@mui/material';

export default function PieceDetail() {

    const { id } = useParams();
    const [piece, setPiece] = useState(undefined);
    const [done, setDone] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        try{
            axios.get(env.BACKEND_API_URL + `/api/piece/${id}/`).then((res) => {
                setPiece(res.data);
                setDone(true);
            })
        } catch (error) {
            if (error.response && error.response.status === 401) {
              navigate('./login'); 
            }
        }
    // eslint-disable-next-line
    }, []);

    function handleMovementClick(id){
        navigate(`/movements/${id}`);
    }

    return(
        <div className="pieces--content">
            <header className="pieces--header">
                <h1>Piece Details</h1>
            </header>
            { !done ? (
                <Spinner />  
            ):(
                <div className="pieces--main">
                    <Paper elevation={3} style={{ padding: '20px', backgroundColor: 'white' }}>
                        <Typography variant="h5" gutterBottom>
                            {piece.title}
                        </Typography>
                        <Typography variant="body1">
                            Composer: {piece.composer.title}
                        </Typography>
                        <Typography variant="body1" style={{ paddingBottom: '8px' }}>
                            Composition Year: {piece.composition_year}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Movements
                        </Typography>
                        <Grid container>
                            <Stack spacing={1} xs={3}>
                                {
                                    piece.movements.map((movement, i) =>{
                                        return(
                                            
                                            <Button 
                                                key={i} 
                                                variant="outlined" 
                                                onClick={e => handleMovementClick(movement.id)}
                                            >
                                                <b>{movement.full_title}</b>
                                            </Button>
                                        )
                                    })
                                }
                            </Stack>
                        </Grid>
                    </Paper>
                </div>
            )}
        </div>
    )
}