import React from "react";
import { Link, useNavigate } from "react-router-dom"
import logo from '../../images/treeimg.png';
import "./Homepage.css";
import { Paper } from '@mui/material';


export default function Homepage() {

    const [searchQuery, setSearchQuery] = React.useState("");

    function handleChange(event) {
        setSearchQuery(event.target.value)
    }

    const navigate = useNavigate();

    function handleSubmit(event) {
        event.preventDefault();
        navigate('/generalsearch', {state: {initQuery: searchQuery}})
    }


    return (
        <div className="hp--container">
            <div className="hp--title">
                <div className="hp--title-text">
                    <h2><span className="title--1">O</span><span className="title--2">RCH</span><span className="title--3">ESTRATION</span></h2>
                    <h2><span className="title--1">A</span><span className="title--3">NALYSIS &</span></h2>
                    <h2><span className="title--1">R</span><span className="title--3">ESEARCH</span></h2>
                    <h2><span className="title--1">D</span><span className="title--3">ATABASE</span></h2>
                </div>
                <img src={logo} alt="orchard logo" />
            </div>
            <div className="hp--general-search">
                <form className="hp--search">
                    <input
                        className="hp--search-bar"
                        type="text"
                        name="search"
                        id="search"
                        placeholder='e.g. "Blends"'
                        onChange={handleChange} />
                    <button
                        className="hp--search-button"
                        onClick={handleSubmit}>Submit</button>
                </form>
            </div>
            <div className="hp--faq">
                <Paper elevation={3}>
                    <h3 className="faq--title">FAQ</h3>
                    <div className="faq--content">
                        <h4 className="faq--subtitle">What is this database</h4>
                        <div className="faq--p">
                            <ul>
                                <li>The <b>Orchestration Analysis and Research Database</b> (Orchard) contains over 4439 annotations
                                    of orchestral grouping effects within 66 full movements of orchestral pieces, spanning 1787-1943
                                    (and one piece from 2004), and continues to grow! </li>
                                <li>Each annotation can be viewed on a detail page, which contains information about the composer,
                                    work, movement, measures, instrumentation, and a brief description. You can view the annotated
                                    score and listen to a short sound clip.</li>
                                <li>See our <a 
                                                href="https://timbreandorchestration.org/tor/modules/taxonomy/orchestral-grouping-effects/introduction" 
                                                className="faq--link"
                                            >About page 
                                            </a> for more information about the background of the project and the types of orchestral effects studied.</li>
                                <li>Due to copyright issues, the database is only available to our research partners. To register
                                    as a research partner, click on <Link to="/registration" className="faq--link">Register</Link>.</li>
                            </ul>
                        </div>
                        <h4 className="faq--subtitle">What can I do?</h4>
                        <div className="faq--p">
                            <ol>
                                <li>Browse the database (top-down approach) by selecting “Orchestral Grouping Effects” or “Models”
                                    in the navigation bar to the right.</li>
                                <li>Simple searches using keywords in the search bar above (automatic text completion will assist you).
                                    Your list of results can be refined through dynamically generated facets (similar to filters on
                                    Amazon.ca) and sorted in various ways. </li>
                                <li>Advanced searches with the <Link to="/querybuilder" className="faq--link">“QueryBuilder Search”</Link> which
                                    allows you to specify the search parameters in a hierarchical format using Boolean operators
                                    (AND, OR). Based on the results list, you can refine your search further by adjusting the
                                    details of the query and through hierarchical sorting.</li>
                                <li>Explore the annotations to study orchestration practices at play! You will soon be able to
                                    export selected annotations for further analyses (e.g., data mining) with the Save Query feature.</li>
                            </ol>
                        </div>
                        <h4 className="faq--subtitle">Who are we?</h4>
                        <div className="faq--p">
                            <ul>
                                <li>To learn more about our interdisciplinary, international research team, please visit our&nbsp;
                                    <a href="https://actorproject.org" className="faq--link">ACTOR website</a>.
                                </li>
                            </ul>
                        </div>
                        <h4 className="faq--subtitle">Questions?</h4>
                        <div className="faq--p">
                            <ul>
                                <li>Did you experience any issues while using our site? Please contact our&nbsp;
                                    <a href="mailto:mpclfeedback@gmail.com" className="faq--link">database designer</a>
                                    &nbsp;or leave feedback using the <Link to="/feedback" className="faq--link">Feedback Form</Link>.</li>
                                <li>Have any questions about the project? Contact&nbsp;
                                    <a href="mailto:mpclfeedback@gmail.com" className="faq--link">our group</a>.</li>
                            </ul>
                        </div>
                    </div>
                </Paper>
            </div>
        </div>
    )
}
