import React, { useState } from 'react';
import "./AnnotationList.css";
import { Box, Grid, Modal, List, ListItemButton, ListItemText, Collapse } from '@mui/material';


export default function AnnotationList(props) {
    const {annotationList} = props;
    const [selectedEvolutions, setSelectedEvolutions] = useState(null);   
    // modal
    const [showPopup, setShowPopup] = useState(false);
    const handleModalOpen = () => setShowPopup(true);
    const handleModalClose = () => setShowPopup(false);
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px',
        overflow: 'hidden',
        overflowY: "scroll",
        maxHeight: '100%'
    };


    const handleEffectClick = (evolutions) => {
        setSelectedEvolutions(evolutions);
        handleModalOpen();
    };

    const EvolutionItem = ({ evolution }) => {

        const [expanded, setExpanded] = React.useState(false)
        const handleExpandClick = () => {
            setExpanded(!expanded)
        };

        return (
            <React.Fragment>
              <ListItemButton  className="evolution--button" onClick={handleExpandClick} style={{backgroundColor: expanded ? "#E0E0E0" : ""}}>
                <ListItemText 
                    primary={"measures " + evolution.measures[0] + "-" + evolution.measures[1] + " (" + evolution.instrumentGroups.length + ")"} 
                    style={{color: "black"}}
                />
              </ListItemButton>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {evolution.instrumentGroups.length !== 0 ? (
                        <ListItemText>
                            <table className="evolution--table">
                                <thead>
                                    <tr>
                                        <th className="evolution--table--th">Instrument Group Type</th>
                                        <th className="evolution--table--th"> Title</th>
                                        <th className="evolution--table--th"> Instruments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        evolution.instrumentGroups.map((group, j) => {
                                            return(
                                                <tr key={j}>
                                                    <td style={{paddingLeft: "15px" }}>{group.type}</td>
                                                    <td style={{paddingLeft: "15px" }}>{group.title}</td>
                                                    <td style={{paddingTop: "10px", marginLeft: "10px" }}>{group.instruments.map(instrument=> 
                                                                                instrument.title
                                                                            ).join(', ')}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <hr />
                        </ListItemText>
                    ):(
                        <p style={{ color: "black", padding: "20px"}}>No information Available</p>
                                                )}
                </List>
              </Collapse>
            </React.Fragment>
        );
    }

    return (
        <div>
            <div>
                <h2>{annotationList.length} annotations</h2>
                <div className='item-container'>
                            
                    {annotationList
                        .sort(function(a, b) {
                            if (a.measuresRanges[1] === b.measuresRanges[1]) {
                                if (a.id > b.id) return 1;
                                return -1;
                            } else {
                                if (a.measuresRanges[1] > b.measuresRanges[1]) return 1;
                                return -1;
                            }
                        })
                        .map((result) => (                          
                        <div key={result.id} onClick={e => handleEffectClick(result.evolutions)}>
                            <Box 
                                sx={{ 
                                    overflow: 'auto',
                                    flexGrow: 1,
                                    textAlign: 'center',
                                    padding: '1em',
                                    width: '100%',
                                    borderRadius: '5px',
                                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                    '&:hover': {
                                        backgroundColor: 'hsl(0, 0%, 90%)',
                                    },
                                }} 
                            >
                                <Grid 
                                    container 
                                    spacing={2}
                                    justifyContent="space-evenly"
                                    alignItems="flex-start"
                                >
                                    <Grid item xs={4} align="left">
                                        <h2>{result.id}) <b>{result.effectType ? result.effectType.replace(/([a-z])([A-Z])/g, '$1 $2'): result.effectType}</b></h2>
                                        <h4>{result.perceptionType}</h4>     
                                        <h4>{result.groupingType} grouping</h4>  
                                    </Grid>
                                    <Grid item xs={4} align="left">                                                        
                                        <div><p>Duration Type -    <b>{result.durationType}</b></p></div>
                                        <div><p>Evolution Type -    <b>{result.evolutionType}</b></p></div>
                                        { result.gestureType ? <div><p>Gesture Type -    <b>{result.gestureType}</b></p></div>: ""}
                                        <div><p>Strength -    <b>{result.strength}</b></p></div>
                                        <div style={{ marginBottom: '0.6rem' }}>
                                            <span>Measure Range :    </span>
                                                { result.measuresRanges[1] !== result.measuresRanges[0] ? (
                                                    <span><b>{result.measuresRanges[0]} - {result.measuresRanges[1]}</b></span>
                                                ) : (
                                                    <span><b>{result.measuresRanges[0]}</b></span>
                                                )}
                                        </div>
                                        <div>
                                            <span>PDF Page(s) :    </span>
                                            { result.pdfPages[1] !== result.pdfPages[0] ? (
                                                <span><b>{result.pdfPages[0]} - {result.pdfPages[1]}</b></span>
                                            ) : (
                                                <span><b>{result.pdfPages[0]}</b></span>
                                            )}
                                        </div>
                                        <div style={{ marginTop: '0.4rem' }}><p>Audio Time :    <b>{result.audioTimeFrames[0]} - {result.audioTimeFrames[1]}</b></p></div>
                                    </Grid>
                                    <Grid item xs={3} align="left">
                                        <div>
                                            <p><b>{result.evolutions.length}</b> evolutions</p>
                                        </div>
                                        { result.notes &&
                                            <div>
                                                <p><b>Notes: </b>{result.notes}</p>
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                        
                    ))}
                </div>
            </div>
            {selectedEvolutions && <Modal
                open={showPopup}
                onClose={handleModalClose}
            >
                <Box sx={modalStyle}>
                    <h3>Evolutions</h3>
                    <List>
                        {selectedEvolutions.map((evolution, index) => (
                            <EvolutionItem key={index} evolution={evolution} />
                        ))}
                    </List>
                </Box>
            </Modal>}
        </div>
    );
}