import React from "react"
import "./Login.css"
import logo from '../../../images/treeimg.png';
import { Link } from "react-router-dom";
import axios from "axios";
import Alert from '@mui/material/Alert';
import { Paper } from '@mui/material';
import env from '../../../env/env';

export default function Login() {

    const [fields, setFields] = React.useState({ username: "", password: "" })
    const [errMsgs, setErrMsgs] = React.useState({ username: "", password: "" })
    const [errorMessage, setErrorMessage] = React.useState("")
    
    /**
     * Sets the values of the form fields
     * 
     * @param {*} event    
     *      onChange event in one of the form fields
     */
    function handleChange(event) {
        setFields(prevFields => {
            return {
                ...prevFields,
                [event.target.name]: event.target.value
            }
        })
    }

    /**
     * Checks the form once it has been submitted
     * 
     * @returns 1 if the form is invalid, redirects otherwise
     */
    function handleSubmit() {
        // Check if all the fields were completed
        if (checkFieldComp()) {
            getToken()
        }
    }

    /**
     * Checks if all fields have been filled in, and sets and error message
     *  if a field is left empty
     * 
     * @returns true if all fields have been filled, false otherwise
     */
    function checkFieldComp() {
        let fieldsComplete = true
        for (const key in fields) {
            if (!fields[key])
                fieldsComplete = false
            setErrMsgs(prevMsgs => {
                return {
                    ...prevMsgs,
                    [key]: fields[key] ? "" : "*You must fill out this field"
                }
            })
        }
        return fieldsComplete
    }

    function getToken() {

        var bodyFormData = new FormData()
        bodyFormData.append("username", fields.username)
        bodyFormData.append("password", fields.password)

        axios.post(env.BACKEND_API_URL + '/api/accounts/login/', bodyFormData)
            .then((res) => {
                if (res.code === "ERR_NETWORK") { 
                    throw new Error("No response from the backend.");
                } else if (res.status !== 200) {
                    if (res?.response.data.detail ) {
                        throw new Error("Invalid username or password. Please try again.");
                    } else if (res.response.data.email) {
                        throw new Error("Invalid username or password. Please try again.");
                    } else {
                        throw new Error("Invalid username or password. Please try again.");
                    }
                }

                // For re-rendering the navbar               
                localStorage.setItem("role", res.data.role)
                localStorage.setItem("access_token", res.data.access)
                localStorage.setItem("username", res.data.username)
                window.location.reload()                     
                // TODO redirect to the page the user was trying to access before logging in     
                window.location.href = "/"  
            })
            .catch((error) => {
                setErrorMessage(String(error))
            });
    }

    return (
        <div className="login--container" >
            <div className="login--content">
                <Paper elevation={3} style={{ padding: '50px', backgroundColor: 'white', marginBottom: "10px"}}>
                    <div className="login--title">
                        <div className="login--title-text">
                            <h2><span className="title--1">O</span><span className="title--2">RCH</span><span className="title--3">ESTRATION</span></h2>
                            <h2><span className="title--1">A</span><span className="title--3">NALYSIS &</span></h2>
                            <h2><span className="title--1">R</span><span className="title--3">ESEARCH</span></h2>
                            <h2><span className="title--1">D</span><span className="title--3">ATABASE</span></h2>
                        </div>
                        <img src={logo} alt="orchard logo" />
                    </div>
                    <div className="login--login">
                        <form className="login--login-form">
                            <div className="login--form-element">
                                <label htmlFor="username">Username</label>
                                <br />
                                <input
                                    type="text"
                                    name="username"
                                    id="username"
                                    onChange={handleChange}
                                    required />
                            </div>
                            <span className="login--err">&nbsp;&nbsp;&nbsp;{errMsgs.username}</span>
                            {errMsgs.username ? <br /> : <></>}
                            <div className="login--form-element">
                                <label htmlFor="password">Password</label>
                                <br />
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    onChange={handleChange}
                                    required />
                            </div>
                            <span className="login--err">&nbsp;&nbsp;&nbsp;{errMsgs.password}</span>
                            {errMsgs.password ? <br /> : <></>}
                        </form>
                        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                        <button className="login--submit" onClick={handleSubmit}>
                            Login
                        </button>
                    </div>
                    <div className="login--utilities">
                        <span>
                            <span>Don't have an account? Create one </span>
                            <Link to="/registration">here</Link>
                            <span>.</span>
                        </span>
                        <span >
                            <span>Forgot password? </span>
                            <Link to="/password-reset">Reset password</Link>
                            <span>.</span>
                        </span>
                        <span >
                            <span>Learn about ORCH.A.R.D. </span>
                            <a href="https://timbreandorchestration.org/tor/modules/taxonomy/orchestral-grouping-effects/introduction" target="_blank">here</a>
                            <span>.</span>
                        </span>
                    </div>
                </Paper>
            </div>
        </div>
    )
}