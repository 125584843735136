import ReactLoading from "react-loading";
import "./small-loading.css";

export default function SmallLoading() {
    return(
        <ReactLoading
            className="search--small-loading "
            type={"spin"}
            color={"#DCC4AE"}
            height={30}
            width={30}
        />
    )
}