import React from "react";
import { Link } from "react-router-dom";
import { Grid, Paper, Typography } from '@mui/material';
// Image file
import logo from '../../../images/treeimg.png';
// CSS file
import "./Registration.css";

export default function RegistrationDone() {
    return (
        <div>
            <div className="hp--container">
            <Paper elevation={3} style={{ padding: '50px', backgroundColor: 'white' }}>
                <div className="reg-done--title" > 
                    <div className="hp--title-text">
                        <h2><span className="title--1">O</span><span className="title--2">RCH</span><span className="title--3">ESTRATION</span></h2>
                        <h2><span className="title--1">A</span><span className="title--3">NALYSIS &</span></h2>
                        <h2><span className="title--1">R</span><span className="title--3">ESEARCH</span></h2>
                        <h2><span className="title--1">D</span><span className="title--3">ATABASE</span></h2>
                    </div>
                    <img src={logo} alt="orchard logo" />
                </div>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    >
                        <Grid item xs={3}>
                                <Typography align="center" variant="h5">
                                    <b>The creation of your account was successful!</b>
                                </Typography>
                                <Typography align="center">
                                    <b>Please note that only accounts who have been authorized by Professor Stephen McAdams will have access to ORCH.A.R.D.</b>
                                </Typography>
                                <Typography align="center">
                                    <b>You will receive a confirmation email once your account has been authorized.</b>
                                </Typography>
                                <Typography align="center">
                                    <b>Learn about ORCH.A.R.D. <a href="https://timbreandorchestration.org/tor/modules/taxonomy/orchestral-grouping-effects/introduction" target="_blank">here</a>.</b>
                                </Typography>
                                <Typography align="center">
                                    <b>Navigate to the login page<Link to="/login"> here</Link>.</b>
                                </Typography>
                        </Grid>
                </Grid>
            </Paper>
            </div>
        </div>
    )
}