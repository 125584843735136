import merge from "lodash/merge";
// import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';
import 'antd/dist/antd.min.css'; // or 
// import "react-awesome-query-builder/css/antd.less";
import AntdWidgets from "react-awesome-query-builder/lib/config/antd";


const InitialConfig = AntdConfig;
const {
    // eslint-disable-next-line
    TextWidget
} = AntdWidgets;
//////////////////////////////////////////////////////////////////////

const fields = {
            grouping_exact: {
                label: "Grouping",
                type: "select",
                valueSources: ["value"],
                excludeOperators: ["select_any_in", "select_not_any_in"],
                fieldSettings: {
                    listValues: [
                        { value: "Concurrent", title: "Concurrent" },
                        { value: "Sequential", title: "Sequential" },
                        { value: "Segmental", title: "Segmental" },
                    ]
                }
            },
            perception_exact: {
                label: "Effect",
                type: "treeselect",
                valueSources: ["value"],
                excludeOperators: ["select_any_in", "select_not_any_in"],
                fieldSettings: {
                    treeExpandAll: true,
                    listValues: [
                        { value: "Concurrent", title: "Concurrent", children: [
                            { value: "Blend", title: "Blend" },
                            { value: "Non Blend", title: "Non-Blend" },
                        ]},
                        { value: "Sequential", title: "Sequential", children: [
                            { value: "Integration", title: "Integration" },
                            { value: "Segregation", title: "Segregation" },
                        ]},
                        { value: "Segmental", title: "Segmental", children: [
                            { value: "Timbral Contrast", title: "Timbral Contrast" },
                            { value: "Timbral Progression", title: "Timbral Progression" },
                        ]}
                    ]
                }
            },
            effect_exact: {
                label: "Sub-Effect",
                type: "treeselect",
                valueSources: ["value"],
                excludeOperators: ["select_any_in", "select_not_any_in"],
                fieldSettings: {
                    treeExpandAll: true,
                    treeSelectOnlyLeafs: true,
                    listValues: [
                        { value: "Concurrent", title: "Concurrent", children: [
                            { value: "Blend", title: "Blend", children: [
                                { value: "Timbral Augmentation", title: "Timbral Augmentation"},
                                { value: "Timbral Emergence", title: "Timbral Emergence"}
                            ]},
                            { value: "Non Blend", title: "Non-Blend", children: [
                                { value: "Timbral Heterogeneity", title: "Timbral Heterogeneity"}
                            ]},
                        ]},
                        { value: "Sequential", title: "Sequential", children: [
                            { value: "Integration", title: "Integration", children: [
                                { value: "Stream", title: "Stream"},
                                { value: "Surface Texture", title: "Surface Texture"}
                            ]},
                            { value: "Segregation", title: "Segregation", children: [
                                { value: "Streams", title: "Streams"},
                                { value: "Stratification", title: "Stratification"}
                            ]},
                        ]},
                        { value: "Segmental", title: "Segmental", children: [
                            { value: "Timbral Contrast", title: "Timbral Contrast", children: [
                                { value: "Antiphonal Contrast", title: "Antiphonal Contrast"},
                                { value: "Timbral Echo", title: "Timbral Echo"},
                                { value: "Timbral Shifts", "title": "Timbral Shifts"},
                                { value: "Timbral juxtaposition", "title": "Timbral juxtaposition"},
                                { value: "Sectional Contrast", "title": "Sectional Contrast"},

                            ]},
                            { value: "Timbral Progression", title: "Timbral Progression", children: [
                                { value: "Orchestral Gestures", title: "Orchestral Gestures"}
                            ]},
                        ]}
                    ]
                }
            },
            duration_exact: {
                label: "Duration Type",
                type: "select",
                valueSources: ["value"],
                operators: ["select_equals"],
                fieldSettings: {
                    listValues: [
                        { value: "Sustained", title: "Sustained" },
                        { value: "Punctuated", title: "Punctuated" },
                    ]
                }
            },
            evolution_exact: {
                label: "Evolution Type",
                type: "select",
                valueSources: ["value"],
                operators: ["select_equals"],
                fieldSettings: {
                    listValues: [
                        { value: "Stable", title: "Stable" },
                        { value: "Transforming", title: "Transforming" },
                    ]
                }
            },
            strength_exact: {
                label: "Strength",
                type: "number",
                valueSources: ["value"],
                preferWidgets: ["rangeslider"],
                operators: ["equal", "less_or_equal", "greater_or_equal", "between"],
                fieldSettings: {
                    min: 1,
                    max: 5,
                    step: 1
                }
            },
            evolution_number_exact: {
                label: "# of Evolutions",
                type: "number",
                valueSources: ["value"],
                preferWidgets: ["rangeslider"],
                operators: ["equal", "less_or_equal", "greater_or_equal", "between"],
                fieldSettings: {
                    min: 1,
                    max: 10,
                    step: 1
                }
            },
            instrument_groups_exact: {
                label: "Instrument group",
                type: "select",
                valueSources: ["value"],
                operators: ["select_equals", "select_not_equals"],
                fieldSettings: {
                    listValues: [
                        { value: "Dominating Instrument", title: "Dominating Instrument" },
                        { value: "Embellishing Instrument", title: "Embellishing Instrument" },
                        { value: "General", title: "General" },
                        { value: "Stream", title: "Stream" },
                        { value: "Stratum Type", title: "Stratum Type" },
                        { value: "Call", title: "Call" },
                        { value: "Response", title: "Response" },
                        { value: "Original", title: "Original" },
                        { value: "Echoing", title: "Echoing" },
                        { value: "Group", title: "Group" },
                    ],
                }
            },
            instruments_exact: {
                label: "Instrumentation",
                type: "select",
                valueSources: ["value"],
                operators: ["select_equals", "select_not_equals", "select_any_in", "select_not_any_in"],
            },
            composer_exact: {
                label: "Composer",
                type: "select",
                valueSources: ["value"],
                operators: ["select_equals", "select_not_equals"],
            },
            conductor_exact: {
                label: "Conductor",
                type: "select",
                valueSources: ["value"],
                operators: ["select_equals", "select_not_equals"],
            },
            piece_exact: {
                label: "Piece",
                type: "select",
                valueSources: ["value"],
                operators: ["select_equals", "select_not_equals"],
            },
            movement_exact: {
                label: "Movement",
                type: "number",
                valueSources: ["value"],
                preferWidgets: ["rangeslider"],
                operators: ["equal", "less_or_equal", "greater_or_equal", "between"],
                fieldSettings: {
                    min: 0,
                    max: 15,
                    step: 1
                }
            },
};


//////////////////////////////////////////////////////////////////////

const conjunctions = {
    AND: InitialConfig.conjunctions.AND,
    OR: InitialConfig.conjunctions.OR,
};


const proximity = {
    ...InitialConfig.operators.proximity,
    valueLabels: [
        { label: "Word 1", placeholder: "Enter first word" },
        { label: "Word 2", placeholder: "Enter second word" },
    ],
    textSeparators: [
        //'Word 1',
        //'Word 2'
    ],
    options: {
        ...InitialConfig.operators.proximity.options,
        optionLabel: "Near", // label on top of "near" selectbox (for config.settings.showLabels==true)
        optionTextBefore: "Near", // label before "near" selectbox (for config.settings.showLabels==false)
        optionPlaceholder: "Select words between", // placeholder for "near" selectbox
        minProximity: 2,
        maxProximity: 10,
        defaults: {
            proximity: 2
        },
        customProps: {}
    }
};

const operators = {
    ...InitialConfig.operators,
    // examples of  overriding
    equal: {
        ...InitialConfig.operators.equal,
        label: "Is"
    },
    not_equal: {
        ...InitialConfig.operators.not_equal,
        label: "Is not"
    },
    select_equals: {
        ...InitialConfig.operators.select_equals,
        label: "Equals"
    },
    select_not_equals: {
        ...InitialConfig.operators.select_not_equals,
        label: "Not equals"
    },
    less: {
        ...InitialConfig.operators.less,
        label: "Less than"
    },
    less_or_equal: {
        ...InitialConfig.operators.less_or_equal,
        label: "Less than or equal to"
    },
    greater: {
        ...InitialConfig.operators.greater,
        label: "Greater than"
    },
    greater_or_equal: {
        ...InitialConfig.operators.greater_or_equal,
        label: "Greater than or equal to"
    },
    between: {
        ...InitialConfig.operators.between,
        valueLabels: [
            "Value from",
            "Value to"
        ],
        textSeparators: [
            "from",
            "to"
        ],
    },
    proximity,
};

const widgets = {
    ...InitialConfig.widgets,
    // examples of  overriding
    text: {
        ...InitialConfig.widgets.text,
    },
    slider: {
        ...InitialConfig.widgets.slider,
        customProps: {
            width: "300px"
        }
    },
    rangeslider: {
        ...InitialConfig.widgets.rangeslider,
        customProps: {
            width: "300px"
        }
    },
    date: {
        ...InitialConfig.widgets.date,
        dateFormat: "DD.MM.YYYY",
        valueFormat: "YYYY-MM-DD",
    },
    time: {
        ...InitialConfig.widgets.time,
        timeFormat: "HH:mm",
        valueFormat: "HH:mm:ss",
    },
    datetime: {
        ...InitialConfig.widgets.datetime,
        timeFormat: "HH:mm",
        dateFormat: "DD.MM.YYYY",
        valueFormat: "YYYY-MM-DD HH:mm:ss",
    },
    func: {
        ...InitialConfig.widgets.func,
        customProps: {
            showSearch: true
        }
    },
    treeselect: {
        ...InitialConfig.widgets.treeselect,
        customProps: {
            showSearch: true
        }
    },
};


const types = {
    ...InitialConfig.types,
    // examples of  overriding
    boolean: merge(InitialConfig.types.boolean, {
        widgets: {
            boolean: {
                widgetProps: {
                    hideOperator: true,
                    operatorInlineLabel: "is",
                }
            },
        },
    }),
};


const localeSettings = {
    locale: {
        short: "ru",
        full: "ru-RU",
    },
    valueLabel: "Value",
    valuePlaceholder: "Value",
    fieldLabel: "Field",
    operatorLabel: "Operator",
    fieldPlaceholder: "Select field",
    operatorPlaceholder: "Select operator",
    deleteLabel: null,
    addGroupLabel: "Add group",
    addRuleLabel: "Add rule",
    delGroupLabel: null,
    notLabel: "Not",
    valueSourcesPopupTitle: "Select value source",
    removeRuleConfirmOptions: {
        title: "Are you sure delete this rule?",
        okText: "Yes",
        okType: "danger",
    },
    removeGroupConfirmOptions: {
        title: "Are you sure delete this group?",
        okText: "Yes",
        okType: "danger",
    },
};

const settings = {
    ...InitialConfig.settings,
    ...localeSettings,

    valueSourcesInfo: {
        value: {
            label: "Value"
        },
        field: {
            label: "Field",
            widget: "field",
        },
        func: {
            label: "Function",
            widget: "func",
        }
    },
    defaultSelectWidth: "250px",
    maxNesting: 4,
    canLeaveEmptyGroup: false, //after deletion
    showErrorMessage: true,
};


const funcs = {
    LOWER: {
        label: "Lowercase",
        mongoFunc: "$toLower",
        jsonLogic: ({ str }) => ({ "method": [str, "toLowerCase"] }),
        returnType: "text",
        args: {
            str: {
                label: "Enter string",
                type: "text",
                valueSources: ["value"],
            },
        }
    },
    LINEAR_REGRESSION: {
        label: "Linear regression",
        returnType: "number",
        formatFunc: ({ coef, bias, val }, _) => `(${coef} * ${val} + ${bias})`,
        sqlFormatFunc: ({ coef, bias, val }) => `(${coef} * ${val} + ${bias})`,
        mongoFormatFunc: ({ coef, bias, val }) => ({ "$sum": [{ "$multiply": [coef, val] }, bias] }),
        jsonLogic: ({ coef, bias, val }) => ({ "+": [{ "*": [coef, val] }, bias] }),
        renderBrackets: ["", ""],
        renderSeps: [" * ", " + "],
        args: {
            coef: {
                label: "Coef",
                type: "number",
                defaultValue: 1,
                valueSources: ["value"],
            },
            val: {
                label: "Value",
                type: "number",
                valueSources: ["value"],
            },
            bias: {
                label: "Bias",
                type: "number",
                defaultValue: 0,
                valueSources: ["value"],
            }
        }
    },
};


const all_configs = {
    conjunctions,
    operators,
    widgets,
    types,
    settings,
    fields,
    funcs
};

export default all_configs;