import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import env from '../../../env/env';
// MUI
import { Accordion, AccordionSummary, AccordionDetails, Alert, Button, Box, 
    Collapse, Grid, IconButton, Modal, Typography  } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/Expand';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';

export default function QueryList() {
    const navigate = useNavigate();

    const [QBqueries, setQBQueries] = useState([]);
    const [GSqueries, setGSQueries] = useState([]);
    const [queryDeletedFlag, setQueryDeletedFlag] = useState(false);

    // alerts
    const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
    const [alertFailureOpen, setAlertFailureOpen] = useState(false);

    // modal variable and functions
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedQuery, setSelectedQuery] = useState({ id: null, name: null });
    const handleModalOpen = (query) => { setModalOpen(true); setSelectedQuery(query); }
    const handleModalClose = () => setModalOpen(false);
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px',
        align: 'center'
      };
    
    // Function for navigating to the right query engine to query
    const HandleGoToQuery = (query) => {
        if (query._type === "QS") {
            navigate('/querybuilder', {state: {queryId: query.id}});
        }
        else if (query._type === "GS") {
            navigate('/generalsearch', {state: {queryId: query.id}});
        }    
    }  

    // API request to get the queries from the current user
    useEffect(() => {
        axios.get(env.BACKEND_API_URL + '/api/accounts/list_queries/')
            .then((res) => {
                setQBQueries(res.data.results.filter((query) => query._type === 'QS')); 
                setGSQueries(res.data.results.filter((query) => query._type === 'GS'));
            })
    }, [queryDeletedFlag]);

    // API request to delete the query
    const deleteQuery = () => {
        axios.delete(env.BACKEND_API_URL + '/api/accounts/delete_query/' + selectedQuery.id + "/")
            .catch(() => { setAlertFailureOpen(true); })
            .finally(() => { setModalOpen(false); setQueryDeletedFlag(!queryDeletedFlag); setAlertSuccessOpen(true)}) 
    };

    return(
        <div>
            <Collapse in={alertSuccessOpen}>
                <Alert
                    variant="standard" severity="success"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertSuccessOpen(false);
                            }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                    >
                    Successfully deleted the query <b>{selectedQuery.name}</b>.
                </Alert>
            </Collapse>
            <Collapse in={alertFailureOpen}>
                <Alert
                    variant="standard" severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertFailureOpen(false);
                            }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                    >
                    Sorry, we were not able to delete the query <b>{selectedQuery.name}</b>. Please, try again later.
                </Alert>
            </Collapse>
            <Accordion disabled={!Boolean(QBqueries.length)} sx={{ maxWidth: '800px' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><b>Query Builder Queries</b></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3}>
                        {QBqueries.map((query, index) => (
                            <Grid 
                                item
                                container 
                                xs={12} 
                                key={index} 
                                sx={{ borderRadius: '16px' }}
                            >
                                <Grid item xs={8}>
                                    <Typography variant="h6" >
                                        {query.name}
                                    </Typography>
                                    <Typography variant="body2">{query.saved_date}</Typography>
                                </Grid>
                                <Grid item xs={3} sx={{ mt: '7px' }}>
                                    <Button 
                                        variant="outlined"
                                        color="success" 
                                        onClick={() => HandleGoToQuery(query)}
                                    ><b>Go to query</b></Button>
                                </Grid>   
                                <Grid item xs={1} sx={{ mt: '7px' }}>
                                    <Button 
                                        variant="outlined"
                                        color="error" 
                                        onClick={() => handleModalOpen(query)}
                                    ><CancelIcon /></Button>
                                </Grid>        
                            </Grid>
                        ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion disabled={!Boolean(GSqueries.length)} sx={{ maxWidth: '800px' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><b>General Search Queries</b></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        {GSqueries.map((query, index) => (
                            <Grid 
                                item
                                container 
                                xs={12} 
                                key={index} 
                                sx={{ borderRadius: '16px' }}
                            >
                                <Grid item xs={8}>
                                    <Typography variant="h6" >
                                        {query.name}
                                    </Typography>
                                    <Typography variant="body2">{query.saved_date}</Typography>
                                </Grid>
                                <Grid item xs={3} sx={{ mt: '7px' }}>
                                    <Button 
                                        variant="outlined"
                                        color="success" 
                                        onClick={() => HandleGoToQuery(query)}
                                    ><b>Go to query</b></Button>
                                </Grid>   
                                <Grid item xs={1} sx={{ mt: '7px' }}>
                                    <Button 
                                        variant="outlined"
                                        color="error" 
                                        onClick={() => handleModalOpen(query)}
                                    ><CancelIcon /></Button>
                                </Grid>        
                            </Grid>
                        ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
            >
                <Box sx={modalStyle}>
                    <Typography variant="h6" align='center'>Are you sure you want to delete the query <b>{selectedQuery.name}</b>?</Typography>
                    <Button 
                        variant="outlined"
                        color="error" 
                        onClick={deleteQuery} 
                        sx={{ left: '40%', mt: '1em' }}
                    ><b>Delete</b></Button>
                </Box>
            </Modal> 
        </div>
    )
}