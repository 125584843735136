// Dependencies
import React, { useState } from "react";
import axios from "axios";
import env from '../../../env/env';
// MUI
import { Box, Button, Modal, TextField } from '@mui/material';


export default function QuerySaver(props) {
    // modal variable and functions
    const [open, setOpen] = useState(false);
    const [childOpen, setChildOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px'
      };

    
    const [queryName, setQueryName] = useState("");
    const handleNameChange = (event) => { setQueryName(event.target.value); };

    // API call to save the query
    const saveQuery = () => {
        let body = {
            parameters : JSON.stringify(props.parameters),
            _type : props.queryType,
            name : queryName
        }

        axios.post(env.BACKEND_API_URL + '/api/accounts/save_query/', body)
        .then(() => {
            setQueryName(""); 
            setChildOpen(true);
        })
        .catch((error) => {
            setChildOpen(true);
        })
    }

    return(
        <div>
            <Box sx={{ml: '2em', mr: '2em', mt: '1em'}}>
                <Button 
                    sx={{
                        width: '100%',
                        maxWidth: '260px'
                    }}
                    variant="contained" 
                    onClick={handleOpen}
                ><b>Save Query</b></Button>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={modalStyle}>
                    <TextField 
                        label="Query Name" 
                        variant="standard" 
                        onChange={handleNameChange}
                        sx={{ minWidth: '600px' }}
                    />
                    <Button 
                        variant="contained" 
                        onClick={saveQuery} 
                        disabled={!queryName}
                        sx={{ ml: '4em', mt: '1em' }}
                    ><b>Save</b></Button>
                    <ChildModal childOpen={childOpen} setChildOpen={setChildOpen} setParentOpen={setOpen} />
                </Box>
            </Modal>
        </div>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

function ChildModal(props) {

    const handleClose = () => {
        props.setChildOpen(false);
        props.setParentOpen(false);
    };
  
    return (
      <React.Fragment>
        <Modal
          open={props.childOpen}
          onClose={handleClose}
        >
          <Box sx={{ ...style, width: 400 }}>
            <h2 >The query was successfully saved! </h2>
            <Button onClick={handleClose}><b>Close</b></Button>
          </Box>
        </Modal>
      </React.Fragment>
    );
}