
const loadedInitValue = {
    "type": "group",
    "id": "initial-example",
    "children1": {
        "initial-example-rule-1": {
            "type": "rule",
            "properties": {
                // "field": "effect",
                // "operator": "select_any_in",
                // "value": ["blend"],
                // "valueSrc": [
                //     "value"
                // ],
                // "valueType": [
                //     "multiselect"
                // ]
            }
        }
    },
};

export default loadedInitValue;