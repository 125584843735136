import React from "react";
import { Link } from "react-router-dom";
import { Typography, Paper } from "@mui/material";
import "./TutorialPage.css";

import start from "../../../images/querybuilder/1_Start.png";
import first_choice from "../../../images/querybuilder/2_FirstChoice.png";
import grouping from "../../../images/querybuilder/3_Grouping.png";
import effect from "../../../images/querybuilder/4_Effect.png";
import subeffecct from "../../../images/querybuilder/5a_subeffect.png";
import timbral_emergence from "../../../images/querybuilder/5b_TimbralEmergence.png";
import add_group from "../../../images/querybuilder/6_AddGroup.png";
import annotation_list from "../../../images/querybuilder/7_AnnotationList.png";
import annotation from "../../../images/querybuilder/8_Annotation.png";
import evolutions_1 from "../../../images/querybuilder/9a_Evolutions1.png";
import evolutions_2 from "../../../images/querybuilder/9b_Evolutions2.png";
import sort_a from "../../../images/querybuilder/10a_Sort.png";
import sort_b from "../../../images/querybuilder/10b_Sort.png";
import saving_a from "../../../images/querybuilder/11a_Saving.png";
import saving_b from "../../../images/querybuilder/11b_Saving.png";


export default function TutorialPage() {
    return (
        <div className="tutpage--container">
            <div className="tutpage--header">
                <h1>QueryBuilder Tutorial</h1>
            </div>
            
            <div className="tutpage--main">
                <Paper elevation={3} style={{ padding: '20px', backgroundColor: 'white' }}>
                    <Link to="/querybuilder">Take me back to the QueryBuilder</Link>
                    <br/>
                    

                    {/* 1 */}
                    <br/>
                    <Typography variant="h5">
                        1. Starting point
                    </Typography>
                    <br/>
                    <Typography variant="body1">
                        When you first navigate to the Query Builder, you will see an empty form. 
                        The results will show all available annotations listed below.
                    </Typography>
                    <img src={start}  className="tutpage--image" alt="tutorial starting point" />
                    <hr/>
                    
                    {/* 2 */}
                    <br/>
                    <Typography variant="h5">
                        2. Choosing a query criterion
                    </Typography>
                    <br/>
                    <Typography variant="body1">
                        When you click in the <b>Select field</b> box, a scrollable drop-down menu will 
                        allow you to choose a criterion.
                    </Typography>
                    <img src={first_choice} className="tutpage--image" alt="tutorial first choice" />
                    <br/>
                    <br/>
                    <Typography variant="body1">
                        You can choose from the following (see <a 
                            href="https://timbreandorchestration.org/tor/modules/taxonomy/orchestral-grouping-effects/introduction"
                        > About page  
                        </a>  for more details on these terms notably the hierarchical structure of the grouping taxonomy):
                    </Typography>
                    <>
                        <ul>
                            <b>Grouping</b> (the main type of auditory grouping: concurrent, sequential, segmental)
                        </ul>
                        <ul>
                            <b>Effect</b> (the main orchestration effect type—concurrent: blend, non-blend; sequential: 
                            integration, segregation; segmental: timbral progression, timbral contrast)
                        </ul>
                        <ul>
                            <b>Sub-effect</b> (sub-categories of effects—blend: timbral augmentation, timbral emergence; 
                            non-blend: timbral heterogeneity; integration: stream, surface texture; segregation—streams, 
                            stratification; timbral progression: orchestral gestures; timbral contrast: antiphonal contrast, 
                            timbral echo, timbral shifts, timbral juxtaposition, sectional contrast)
                        </ul>
                        <ul>
                            <b>Duration type</b> (sustained over time or punctuated)
                        </ul>
                        <ul>
                            <b>Evolution type</b> (stable or transforming instrumentation over the annotation)
                        </ul>
                        <ul>
                            <b>Strength</b> (annotated strength of the grouping effect)
                        </ul>
                        <ul>
                            <b># of Evolution</b> (the instruments involved in a stable effect, or the different 
                            instruments involved in each stage of a transforming effect)
                        </ul>
                        <ul>
                            <b>Instrument group</b> (type of group of instruments involved)
                        </ul>
                        <ul>
                            <b>Instrumentation</b> (specification of a search for individual instruments)
                        </ul>
                        <ul>
                            <b>Composer</b> (composer of the score)
                        </ul>
                        <ul>
                            <b>Conductor</b> (conductor of the recording used for the annotation)
                        </ul>
                        <ul>
                            <b>Piece</b> (name of score)
                        </ul>
                        <ul>
                            <b>Movement</b> (movement number for pieces with two or more movements)
                        </ul>
                    </>
                    <Typography variant="body1">
                        It is best to start with <b>Grouping</b> or <b>Effect</b> and to build the query from there. 
                        Once a criterion is selected, additional fields appear. 
                        For all categorical criteria, the next field is <b>Equals</b>, 
                        and the last field presents possibilities related to the first field. 
                        For numerical criteria like <b>Strength</b> (on a 1 to 5 scale) or <b># of Evolutions</b>, 
                        you can specify an exact value (<b>Is</b>), a value less than/greater than or equal to the specified value, 
                        or values between an upper and lower bound. <b>Instrumentation</b> is a special case in which you specify 
                        that instrumentation equals or is not equal to a specified name or is any name in (or not in) a character string. 
                        For example, selecting <b>Any in</b> and typing “alto” gives a drop-down menu of alto saxophone 1, alto saxophone 2, 
                        alto trombone. Or typing “flute” gives flute 1, flute 2, flute 3, flute 4. 
                        If you just wanted “flute” without specifying which one, you would need to create a group, 
                        select <b>Is</b> and flute 1, add 3 rules for flutes 2 to 4 and click on the <b>Or</b> Boolean operator. 
                        If the system does not recognize the character string, nothing happens.
                    </Typography>
                    <hr/>

                    {/* 3 */}
                    <br/>
                    <Typography variant="h5">
                        3. Choosing a category of a selected criterion
                    </Typography>
                    <br/>
                    <Typography variant="body1">
                        Click on <b>Select value</b> and a drop-down menu specific to the criterion appears. 
                        For <b>Grouping</b>, it can be <b>Concurrent</b>, <b>Sequential</b>, or <b>Segmental</b> grouping.
                    </Typography>
                    <img src={grouping} className="tutpage--image" alt="tutorial grouping" />
                    <hr/>

                    {/* 4 */}
                    <br/>
                    <Typography variant="h5">
                        4. Choosing an orchestration effect
                    </Typography>
                    <br/>
                    <Typography variant="body1">
                        Choosing <b>Effect</b> gives one access to the main categories of effects across all three grouping categories. 
                        Select the desired effect and click on <b>Submit Query</b>.
                    </Typography>
                    <img src={effect} className="tutpage--image" alt="tutorial effect" />
                    <hr/>

                    {/* 5 */}
                    <br/>
                    <Typography variant="h5">
                        5. Adding rules
                    </Typography>
                    <br/>
                    <Typography variant="body1">
                        Clicking on the <b>Add rule</b> button creates a new criterion. 
                        To refine the effect, select <b>Sub-effect</b> and then a relevant sub-effect from the drop-down menu.
                    </Typography>
                    <img src={subeffecct} className="tutpage--image" alt="tutorial subeffect" />
                    <br/>
                    <br/>
                    <Typography variant="body1">
                        If you choose <b>Timbral Emergence</b> (one type of blend) and then click <b>Submit Query</b>, 
                        the following results are obtained. Note that the Boolean operator here is <b>And</b>, 
                        so the query requires that the annotation be both a blend and a timbral emergence. 
                        Alternatively, given that the sub-effects are nested within the effects, you can go 
                        straight to <b>Sub-effect</b> for a query if you know what you are looking for.
                    </Typography>
                    <img src={timbral_emergence} className="tutpage--image" alt="tutorial timbral emergence" />
                    <hr/>

                    {/* 6 */}
                    <br/>
                    <Typography variant="h5">
                        6. Forming groups of rules
                    </Typography>
                    <br/>
                    <Typography variant="body1">
                        Clicking on <b>Add group</b> creates a group with one rule to which other rules can be added inside the group. 
                    </Typography>
                    <img src={add_group} className="tutpage--image" alt="tutorial add group" />
                    <br/>
                    <br/>
                    <Typography variant="body1">
                        Groups are evaluated and their results are then processed with the other rules. 
                        In the case above, the group specifies that we want the <b>Composer</b> to be Debussy OR Mahler. 
                        This query will provide all the annotations of pieces with either Debussy or Mahler that 
                        refer to timbral emergence blends. So the query says Effect=Blend AND Sub-effect=Timbre 
                        Emergence AND (Composer=Debussy OR Composer=Mahler). There are 67 such annotations in OrchARD 
                        as you can see at the top of the annotation list.
                    </Typography>
                    <hr/>

                    {/* 7 */}
                    <br/>
                    <Typography variant="h5">
                        7. List of selected annotations
                    </Typography>
                    <br/>
                    <Typography variant="body1">
                    The list of annotations appears below the query builder and can be scrolled through. 
                    It may have several pages of annotations. 
                    <b> Prev</b> and <b>Next</b> at the top or bottom of the displayed list allow you to move the previous of next page.
                    </Typography>
                    <img src={annotation_list} className="tutpage--image" alt="tutorial annotation list" />
                    <hr/>

                    {/* 8 */}
                    <br/>
                    <Typography variant="h5">
                        8. Annotation Display
                    </Typography>
                    <br/>
                    <Typography variant="body1">
                        Clicking on an annotation from the resulting list opens the display window with the annotated score, 
                        meta-data related to the piece, and a player with the audio clip of this point in the recording.
                    </Typography>
                    <img src={annotation} className="tutpage--image" alt="tutorial annotation" />
                    <br/>
                    <br/>
                    <Typography variant="body1">
                        The meta-data include composer, piece, movement, the orchestra, the duration type 
                        (sustained or punctuated), the evolution type (stable or transforming in instrumentation), 
                        the measure, time in the recording used for the annotation, the annotators’ strength of effect 
                        rating, score pages, annotators’ notes, and the relevant instruments.
                    </Typography>
                    <hr/>

                    {/* 9 */}
                    <br/>
                    <Typography variant="h5">
                        9. Evolutions
                    </Typography>
                    <br/>
                    <Typography variant="body1">
                        Some annotations (primarily more recent annotations) that have a transforming evolution list with 
                        several Evolution numbers.
                    </Typography>
                    <img src={evolutions_1} className="tutpage--image" alt="tutorial evolutions 1" />
                    <br/>
                    <br/>
                    <Typography variant="body1">
                        Clicking on the evolution number displays the instruments involved in a particular phase of the evolution. 
                    </Typography>
                    <img src={evolutions_2} className="tutpage--image" alt="tutorial evolutions 2" />
                    <br/>
                    <br/>
                    <Typography variant="body1">
                        In this case of stream segregation, the second evolution had cello, clarinet, oboe, 
                        and viola blended in one stream and celesta in the other stream. 
                        Click on the annotation page to remove the evolution pop-up.
                    </Typography>
                    <hr/>

                    {/* 10 */}
                    <br/>
                    <Typography variant="h5">
                        10. Sorting annotation list
                    </Typography>
                    <br/>
                    <Typography variant="body1">
                        There are up to five fields that can be used to sort the annotation list hierarchically 
                        by certain criteria to make it easier to search through.
                    </Typography>
                    <img src={sort_a} className="tutpage--image" alt="tutorial sort a" />
                    <br/>
                    <br/>
                    <Typography variant="body1">
                        In this case, it sorts by year in descending order (new->old) as well as in ascending order by 
                        composer, piece name, movement number, and the effect strength.
                    </Typography>
                    <img src={sort_b} className="tutpage--image" alt="tutorial sort b" />
                    <hr/>
                    
                    {/* 11 */}
                    <br/>
                    <Typography variant="h5">
                        11. Saving queries
                    </Typography>
                    <br/>
                    <Typography variant="body1">
                        Each user can save queries, which is particularly useful as one refines complex queries to get 
                        at a particular orchestration research question.
                    </Typography>
                    <img src={saving_a} className="tutpage--image" alt="tutorial saving a" />
                    <br/>
                    <br/>
                    <Typography variant="body1">
                        Clicking on the <b>SAVE QUERY</b> button opens a pop-up window to name the query. Then click <b>SAVE</b>.
                    </Typography>
                    <img src={saving_b} className="tutpage--image" alt="tutorial saving b" />
                    <br/>
                    <Typography variant="body1">
                        Then in the left-hand menu, click on <b>My Profile</b> and then the <b>SAVED QUERIES</b> tab. 
                        Then click on <b>Query Builder Queries</b> and the list of saved queries appears. 
                        You can go back to specific queries by clicking on <b>GO TO QUERY</b> and it will be reinstated in the Query Builder. 
                        You will still need to click on <b>Submit Query</b> for it to access the database. 
                        Or it can be deleted by clicking on the red X.
                    </Typography>
                    <hr/>

                    <br/>
                    <Typography variant="body1">
                        Hopefully this tutorial will help new users get acquainted. 
                        Please find more analysis information on the  
                        <a 
                            href="https://timbreandorchestration.org/tor/modules/taxonomy/orchestral-grouping-effects/introduction"
                        > About page  
                        </a> contact info 
                        and FAQs at the bottom of the <Link to="/homepage">Home page</Link>. 
                        And don’t hesitate to provide <Link to="/feedback">Feedback</Link> on your experience with OrchARD.
                    </Typography>

                </Paper>               
            </div>
        </div>

    )
}