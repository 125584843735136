import React, { useEffect, useState} from "react";
import axios from "axios";
import env from '../../../env/env';
import Spinner from "../../core/spinner/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import "./MovementDetails.css"
import { Button, Typography, Grid, List, ListItem, ListItemText, Paper } from "@mui/material";
import { styled } from '@mui/material/styles';


export default function MovementDetails() {

    const { id } = useParams();
    const [movement, setMovement] = useState(undefined);
    const [done, setDone] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(env.BACKEND_API_URL + `/api/movement/${id}/`).then((res) => {
            
            if (res.status !== 200) {
                throw new Error(res.response.status);
            }

            setMovement(res.data);
            setDone(true);
            
        }).catch((err) => {
            if (err.message === "401") {
                navigate('./login'); 
            }
            if (err.message === "500") {
                alert("Error fetching the data");
            }
        })
        
    // eslint-disable-next-line
    }, []);

    function handleEffectClick(effect_id){
        navigate(`/effect/${effect_id}`);
    }

    function handlePieceClick(id){
        navigate(`/pieces/${id}`);
    }

    const StyledListItem = styled(ListItem)(({ theme }) => ({
        padding: theme.spacing(1), 
        border: '1px solid #ccc', 
        width: "95%",
        borderRadius: "0.8em",
        fontWeight: 10000,
        // color: "#3F3C3C",
        color: "#0066CC",
        backgroundColor: "white"
    }));

    return(
        <div className="movements--content">
            <header className="movements--header">
                <h1>Movement Details</h1>
            </header>
            { !done ? (
                <Spinner />
        ):(
            <div className="movements--main">
                <Paper elevation={3} style={{ padding: '20px', backgroundColor: 'white' }}>
                    <div className="movements--top">
                        <div className="movements--title">
                            <Typography variant="h4">{movement.name}</Typography>
                        </div>
                        <div className="movements--piece">
                            <Typography variant="body1">Piece: 
                                <Button 
                                    variant="text" 
                                    onClick={e => handlePieceClick(movement.piece.id)}
                                >
                                    <b>{movement.piece.title}</b>
                                </Button> 
                            </Typography>
                            <Typography variant="body1">
                                Score Publisher: {movement.score.publisher}
                            </Typography>
                            <Typography variant="body1">
                                Publishing Location: {movement.score.publishing_location}
                            </Typography>
                            <Typography variant="body1" style={{ paddingBottom: '10px' }}>
                                Publishing Year: {movement.score.publishing_year}
                            </Typography>
                        </div>
                        <div className="movements--instruments">
                            <Typography variant="body1">Scored Instruments: </Typography>
                            <Typography variant="body2">{movement.scored_instruments.join(', ')}</Typography>
                        </div>
                    </div>
                    <div className="movements--bottom">
                        <Paper elevation={3} style={{ padding: '20px', backgroundColor: 'white'}}>
                            <Typography align="center" variant="h5"><b>Effects</b></Typography>
                            <br />
                            <Grid container spacing={0}>
                                { movement.effects["BL"].count > 0 &&
                                <Grid item xs={2}>
                                    <Typography variant="body1" align="center"><b>Blends ({movement.effects["BL"].count})</b></Typography>
                                    <List>
                                        {movement.effects["BL"].effect_list.map((eff) => {
                                            return(
                                                <StyledListItem 
                                                    key={eff.id} 
                                                    button
                                                    onClick={e => handleEffectClick(eff.id)} 
                                                >
                                                    <ListItemText
                                                        primary={<Typography variant="body2" noWrap align="center"><b>{eff.effect_type}</b></Typography>}
                                                        secondary={<Typography variant="body2" noWrap align="center"><b>m.m {eff.first_bar}-{eff.last_bar}</b></Typography>}
                                                    />
                                                </StyledListItem>
                                                
                                            )
                                        })}  
                                    </List>                          
                                </Grid>}
                                { movement.effects["NB"].count > 0 &&
                                <Grid item xs={2}>
                                    <Typography variant="body1" align="center"><b>Non Blends ({movement.effects["NB"].count})</b></Typography>
                                    <List>
                                        {movement.effects["NB"].effect_list.map((eff) => {
                                            return(
                                                <StyledListItem 
                                                    key={eff.id} 
                                                    button 
                                                    onClick={e => handleEffectClick(eff.id)} 
                                                    sx={{ backgroundColor: 'white' }}
                                                >
                                                    <ListItemText
                                                        primary={<Typography variant="body2" noWrap align="center"><b>{eff.effect_type}</b></Typography>}
                                                        secondary={<Typography variant="body2" noWrap align="center"><b>m.m {eff.first_bar}-{eff.last_bar}</b></Typography>}
                                                    />
                                                </StyledListItem>
                                                
                                            )
                                        })}  
                                    </List> 
                                </Grid>}
                                { movement.effects["SE"].count > 0 &&
                                <Grid item xs={2}>
                                    <Typography variant="body1" align="center"><b>Segregations ({movement.effects["SE"].count})</b></Typography>
                                    <List>
                                        {movement.effects["SE"].effect_list.map((eff) => {
                                            return(
                                                <StyledListItem 
                                                    key={eff.id} 
                                                    button 
                                                    onClick={e => handleEffectClick(eff.id)} 
                                                    sx={{ backgroundColor: 'white' }}
                                                >
                                                    <ListItemText
                                                        primary={<Typography variant="body2" noWrap align="center"><b>{eff.effect_type}</b></Typography>}
                                                        secondary={<Typography variant="body2" noWrap align="center"><b>m.m {eff.first_bar}-{eff.last_bar}</b></Typography>}
                                                    />
                                                </StyledListItem>
                                                
                                            )
                                        })}  
                                    </List> 
                                </Grid>}
                                { movement.effects["IN"].count > 0 &&
                                <Grid item xs={2}>
                                    <Typography variant="body1" align="center"><b>Integrations ({movement.effects["IN"].count})</b></Typography>
                                    <List>
                                        {movement.effects["IN"].effect_list.map((eff) => {
                                            return(
                                                <StyledListItem 
                                                    key={eff.id} 
                                                    button 
                                                    onClick={e => handleEffectClick(eff.id)} 
                                                    sx={{ backgroundColor: 'white' }}
                                                >
                                                    <ListItemText
                                                        primary={<Typography variant="body2" noWrap align="center"><b>{eff.effect_type}</b></Typography>}
                                                        secondary={<Typography variant="body2" noWrap align="center"><b>m.m {eff.first_bar}-{eff.last_bar}</b></Typography>}
                                                    />
                                                </StyledListItem>
                                                
                                            )
                                        })}  
                                    </List> 
                                </Grid>}
                                { movement.effects["TC"].count > 0 &&
                                <Grid item xs={2}>
                                    <Typography variant="body1" align="center"><b>Timbral Contrasts ({movement.effects["TC"].count})</b></Typography>
                                    <List>
                                        {movement.effects["TC"].effect_list.map((eff) => {
                                            return(
                                                <StyledListItem 
                                                    key={eff.id} 
                                                    button 
                                                    onClick={e => handleEffectClick(eff.id)} 
                                                    sx={{ backgroundColor: 'white' }}
                                                >
                                                    <ListItemText
                                                        primary={<Typography variant="body2" noWrap align="center"><b>{eff.effect_type}</b></Typography>}
                                                        secondary={<Typography variant="body2" noWrap align="center"><b>m.m {eff.first_bar}-{eff.last_bar}</b></Typography>}
                                                    />
                                                </StyledListItem>
                                                
                                            )
                                        })}  
                                    </List> 
                                </Grid>}
                                { movement.effects["TP"].count > 0 &&
                                <Grid item xs={2}>
                                    <Typography variant="body1" align="center"><b>Timbral Progressions ({movement.effects["TP"].count})</b></Typography>
                                    <List>
                                        {movement.effects["TP"].effect_list.map((eff) => {
                                            return(
                                                <StyledListItem 
                                                    key={eff.id} 
                                                    button 
                                                    onClick={e => handleEffectClick(eff.id)} 
                                                    sx={{ backgroundColor: 'white' }}
                                                >
                                                    <ListItemText
                                                        primary={<Typography variant="body2" noWrap align="center"><b>{eff.effect_type}</b></Typography>}
                                                        secondary={<Typography variant="body2" noWrap align="center"><b>m.m {eff.first_bar}-{eff.last_bar}</b></Typography>}
                                                    />
                                                </StyledListItem>
                                                
                                            )
                                        })}  
                                    </List> 
                                </Grid>}
                            </Grid>  
                        </Paper>
                    </div>
                </Paper>
            </div>
        )}
        </div>
        
    )

}