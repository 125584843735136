// Dependencies
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import env from '../../env/env';
// Material UI
import { Button, ButtonGroup, Grid, Box, Paper, Typography } from "@mui/material";
// Spinners
import SmallLoading from "../core/spinner/small-loading/small-loading";
import BigLoading from "../core/spinner/big-loading/big-loading";
// Style
import "./GeneralSearch.css";
// Components
import SortHierarchy from "../querybuilder/sort-hierarchy/SortHierarchy";
import QuerySaver from "../querybuilder/query-saver/QuerySaver";


export default function GeneralSearch() {
    const location = useLocation();
    const navigate = useNavigate();

    const [searchResult, setSearchResult] = useState([]);
    const [page, setPage] = useState(1);
    const [numPages, setnumPages] = useState(0);
    const [numResults, setnumResults] = useState(0);
    const [done, setDone] = useState(undefined);
    const [changePageDone, setChangePageDone] = useState(undefined);
    const [searchQuery, setSearchQuery] = useState(location.state?.initQuery ? location.state.initQuery : "Blends");

    const [sortingQuery, setSortingQuery] = useState([]);

    useEffect(() => {
        if(location.state?.queryId) {
            // API request to retrieve the query
            axios.get(env.BACKEND_API_URL + '/api/accounts/retrieve_query/' + location.state.queryId + "/")
                .then((res) => { 
                    setSearchQuery(JSON.parse(res.data.parameters));
                }) 
        }
    }, [])

    // Update the searchQuery when the searchQuery or page changes
    useEffect(() => {
        let QUERY = {
            sort: sortingQuery,            
            query: {
                multi_match: {
                    query: searchQuery,
                    fields: [
                        // Boost the importance of fields as needed
                        'effect^2', 
                        'perception^2', 
                        'grouping^2',
                        'composer^1',
                        'composer_full_title^1',
                        'piece^1',
                        'orchestra^1',
                        'instrument^1',
                        'notes^0.5'
                    ], 
                    type: 'best_fields', // This selects the best matching field
                    fuzziness: 1
                },
            },         
            from: (page - 1) * 10,
            size: 10
        }

        axios.post(env.ELASTIC_API_URL + '/orchard_index/_search/', QUERY,
        {
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            setSearchResult(res.data.hits.hits);
            setnumPages( Math.ceil(res.data.hits.total.value / 10));
            setnumResults(res.data.hits.total.value);
            setChangePageDone(true);
            setDone(true);
        })
        .catch((error) => {
            console.error('Error:', error.message);
    
            if (error.response && error.response.status === 401) {
              navigate('./login'); 
            }
        });
    // eslint-disable-next-line 
    }, [searchQuery, sortingQuery, page])
    
    // Elastic Search API call with query
    function handleSubmit(event) {    
        event.preventDefault();
        if (searchQuery !== document.getElementById("searchBar").value) {
            setDone(false);
            setPage(1); // Reset the page to 1 for a new query
            setSearchQuery(document.getElementById("searchBar").value);
        }
    }

    // Page navigation functions
    function handleNextPageClick() {
        setChangePageDone(false);
        setPage(page + 1)    
    }

    function handlePrevPageClick() {
        setChangePageDone(false);
        setPage(page - 1)
    }

    // Effect navigation function
    function handleEffectClick(django_id){
        navigate(`/effect/${django_id}`);
    }

    // Handle Child SortHierarchy component updating sorting
    const handleSortingCallback = (childData) => {
        setSortingQuery(childData);
        setPage(1);
    }

    function Results() {
        return(
            <div>
                <div className="search--results-header">
                    <ButtonGroup className="search--results-page" variant="outlined" size="large" aria-label="large button group">
                        <Button onClick={handlePrevPageClick} disabled={page === 1}> Prev</Button>
                        <Button onClick={handleNextPageClick} disabled={page === numPages}> Next</Button>
                    </ButtonGroup>          
                    {numPages !== 0 &&  <h3 style={{ marginLeft: "20px"}}>Page {page} of {numPages} - {numResults} results</h3>}
                    {!changePageDone && <SmallLoading />}
                </div>
                <div className='item-container'>
                            
                    {searchResult.map((result) => (      
                        <div key={result._id} onClick={e => handleEffectClick(result._source.django_id)}>                    
                            <Box 
                                key={result._id}
                                sx={{
                                    overflow: 'auto',
                                    flexGrow: 1,
                                    textAlign: 'center',
                                    padding: '1em',
                                    width: '100%',
                                    borderRadius: '5px',
                                    border: 1,
                                    borderColor: 'grey.300',
                                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                    '&:hover': {
                                        backgroundColor: 'hsl(0, 0%, 90%)',
                                    },
                                }} 
                            >
                                <Typography
                                    variant="h5" 
                                    style={{textAlign: 'left', marginBottom: '16px'}}
                                >{result._source.effect} ({result._source.perception} - {result._source.grouping} grouping)</Typography>
                                <Grid 
                                    container 
                                    spacing={2}
                                    justifyContent="space-evenly"
                                    alignItems="flex-start"
                                >
                                    <Grid item xs={4} align="left">
                                        <Typography><b>{result._source.work}</b></Typography>
                                        <Typography>Composed: <b>{result._source.composition_year}</b></Typography>
                                        <Typography>Strength: <b>{result._source.strength}</b></Typography>
                                        <Typography>Duration: <b>{result._source.duration}</b></Typography>
                                        <Typography>Evolution: <b>{result._source.evolution}</b></Typography>
                                        <Typography>mm: <b>{result._source.first_bar}-{result._source.last_bar}</b></Typography>
                                    </Grid>
                                    <Grid item xs={4} align="left">                                                        
                                        <Typography>Instrumentation: {result._source.instruments.join(", ")}</Typography>
                                    </Grid>
                                    <Grid item xs={4} align="left">
                                        { result._source.notes &&
                                            <Typography>Notes: {result._source.notes}</Typography> 
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    ))}
                </div>
                
                <div className="search--results-header">
                    <ButtonGroup className="search--results-page" variant="outlined" size="large" aria-label="large button group">
                        <Button onClick={handlePrevPageClick} disabled={page === 1}> Prev</Button>
                        <Button onClick={handleNextPageClick} disabled={page === numPages}> Next</Button>
                    </ButtonGroup>
                    {numPages !== 0 &&  <h3 style={{ marginLeft: "20px"}}> Page {page} of {numPages}</h3>}
                    {!changePageDone && <SmallLoading />}
                </div>
            </div>
        )
    }

    return (
        <div className="search--content">
            <header className="search--header">
                <h1 className="search--header-h1">General Search</h1>
            </header>
            <div className="search--main">
                <Paper elevation={3} style={{ padding: '20px', backgroundColor: 'white' }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3} sx={{mt:'2em'}}>
                                <SortHierarchy 
                                    handleSortingCallback={handleSortingCallback}
                                />
                                <QuerySaver queryType="GS" parameters={searchQuery}/>
                            </Grid>
                            <Grid item xs={9}>
                                <form className="search--search">
                                    <input 
                                        className="search--search-bar"
                                        type="text"
                                        name="search"
                                        id="searchBar"
                                        placeholder={ searchQuery ? searchQuery : 'e.g "Blends"'}
                                    />
                                    <button 
                                        className="search--search-button"
                                        onClick={handleSubmit}>Search</button>
                                </form>
                                <div className="search--results">
                                    {!done ? (
                                        <BigLoading />
                                    ):(
                                        searchResult.length > 0 ? <Results /> : <Typography sx={{ml:'20%', mt:'1em'}}>No results found.</Typography>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </div>
        </div>   
    )
}