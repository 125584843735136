import React from 'react';
import "./InstrumentList.css";


export default function InstrumentList(props) {
    const {instrumentList} = props;

    return (
        <div>
            { instrumentList ? (
                <div>
                    <p>{instrumentList.length} instruments</p>               
                    {instrumentList.map((instrument, index) => (
                        <div key={index}>
                            <ul>
                                <li>{instrument.title}</li>
                            </ul>
                        </div>)
                    )}
                </div>
                ) : (
                <div>
                   <p>The list of the instruments present in the annotations is
                    in not in this xml file.</p> 
                </div>
            )}
        </div>
    );
}