import React, { useEffect, useState } from "react";
import axios from "axios";
import env from '../../env/env';
import "./Conductors.css";
import Spinner from "../core/spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { Table, TableBody, TableCell, TableHead, TableRow, 
         TableContainer, Paper, Button, Stack, Typography} from "@mui/material";

export default function Conductors() {

    const [conductors, setConductors] = useState([]);
    const [done, setDone] = useState(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(env.BACKEND_API_URL + '/api/conductor/').then((res) => {
            setConductors(res.data.results);
            setDone(true);          
        })
    }, []);

    function handleRecordingClick(id) {
        navigate(`/movements/${id}`);
    }

    return (
        <div className="conductors--content">
            <header className="conductors--header">
                <h1>Conductors</h1>
            </header>
            { !done ? (
                <Spinner />
            ):(
                <div className="conductors--main">
                    <TableContainer component={Paper} sx={{width: "90%"}}>
                        <Table>
                            <TableHead>
                                <TableCell sx={{fontSize: '24px', fontWeight: "600"}}>Conductors</TableCell>
                                <TableCell align="center" sx={{ fontSize: '24px', fontWeight: "600"}}>Movements</TableCell>
                            </TableHead>
                            <TableBody>
                                {
                                    conductors.map((conductor, i) => {
                                        return(
                                            <TableRow key={i}>
                                                <TableCell style={{verticalAlign:"top"}}>
                                                    <Typography 
                                                        variant="h6"
                                                        align="left"
                                                    >
                                                        {conductor.full_name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center"  style={{verticalAlign: "top", fontSize: '16px'}}>
                                                    <Stack spacing={0}>
                                                        {
                                                            conductor.recordings.map((recording, j) => {
                                                                return(
                                                                    <div key={j}>
                                                                        <Button variant="text" onClick={e => handleRecordingClick(recording.id)}>
                                                                            <b>{recording.title}</b>
                                                                        </Button>
                                                                    </div>                                                      
                                                                )
                                                            })
                                                        }
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}
        </div>
    )
}