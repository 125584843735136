import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import env from '../../../env/env';
import { Query, Builder, Utils as QbUtils } from "react-awesome-query-builder";
import loadedConfig from "./Configs/minimal_configs";
import loadedInitValue from "./Configs/init_value";
import { Button, ButtonGroup, Grid, Box, Typography } from "@mui/material";
// Spinners
import SmallLoading from "../../core/spinner/small-loading/small-loading";
import BigLoading from "../../core/spinner/big-loading/big-loading";
// Style
import "./Main.css"
// Preload qb fields
import fetchConductors from "./qb-field-fetching/FetchConductors";
import fetchComposers from "./qb-field-fetching/FetchComposers";
import fetchPieces from "./qb-field-fetching/FetchPieces";
import fetchInstrumentation from "./qb-field-fetching/FetchInstrumentation";



const { jsonLogicFormat, elasticSearchFormat, getTree, checkTree, loadTree, uuid } = QbUtils;
//const preStyle = { backgroundColor: "darkgrey", margin: "10px", padding: "10px" };
//const preErrorStyle = { backgroundColor: "lightpink", margin: "10px", padding: "10px" };

const emptyInitValue = { "id": uuid(), "type": "group" };

// get init value in JsonTree format:
const initValue = loadedInitValue && Object.keys(loadedInitValue).length > 0 ? loadedInitValue : emptyInitValue;
const initTree = checkTree(loadTree(initValue), loadedConfig);

// -OR- alternativaly get init value in JsonLogic format:
//const initLogic = loadedInitLogic && Object.keys(loadedInitLogic).length > 0 ? loadedInitLogic : undefined;
//const initTree = checkTree(loadFromJsonLogic(initLogic, loadedConfig), loadedConfig);


export default function QueryBuilder(props) {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [tree, setTree] = useState(initTree);
    const [config, setConfig] = useState(loadedConfig);
    const [query, setQuery] = useState(undefined);

    const [page, setPage] = useState(1);
    const [results, setResults] = useState([]);
    const [numPages, setnumPages] = useState(0);
    const [numResults, setnumResults] = useState(0);
    const [done, setDone] = useState(undefined);
    const [changePageDone, setChangePageDone] = useState(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        
        fetchQuery();

        fetchComposers(setConfig);
        fetchPieces(setConfig);
        fetchConductors(setConfig);
        fetchInstrumentation(setConfig);

    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setPage(1);
    }, [props.sortingQuery])

    

    useEffect(() => {

        let QUERY = {
            sort: props.sortingQuery,
            query: query,
            from: (page - 1) * 10,
            size: 10
        }

        axios.post(env.ELASTIC_API_URL + '/orchard_index/_search/', QUERY,
        {
            headers: {
              'Content-Type': 'application/json'
            },
        }).then((res) => {       
            setResults(res.data.hits.hits);
            setnumPages( Math.ceil(res.data.hits.total.value / 10));
            setnumResults(res.data.hits.total.value);
        }).finally(() => {
            setChangePageDone(true);
            setDone(true);
        })
    }, [page, props.sortingQuery, query])


    /**
     * This function is intended to be used as a React effect to fetch the query in order to display them
     * in the querybuilder.
     */
    function fetchQuery() {
        if(location.state?.queryId) {
            // API request to retrieve the query
            axios.get(env.BACKEND_API_URL + '/accounts/retrieve_query/' + location.state.queryId + "/")
                .then((res) => { 
                    setTree(loadTree(JSON.parse(res.data.parameters)));
                }) 
        } else {           
            const urlQuery = searchParams.get("query");

            if (urlQuery) {
                setTree(loadTree(JSON.parse(urlQuery)));
            }
        }
    }

 
    /**
     * Submits the query to the backend and logs the result to the console
     */
    function handleSubmit() {
        if (query !== elasticSearchFormat(tree, config)) {
            setDone(false);
            setQuery(elasticSearchFormat(tree, config));
            setPage(1);
        }
    }


    /**
     * Default react-awesome-query-builder function to reset the value of the query to init_value.js
     */
    function resetValue() {
        setTree(initTree)
    };

    /**
     * Default react-awesome-query-builder function to clear the value of the query
     */
    function clearValue() {
        setTree(loadTree(emptyInitValue));
    };

    /**
     * Default react-awesome-query-builder function to render the querybuilder component
     */
    function renderBuilder(props) {
        return (
            <div className="query-builder-container" style={{ padding: "10px" }}>
                <div className="query-builder">
                    <Builder {...props} />
                </div>
            </div>
        )
    }

    /**
     * Default react-awesome-query-builder function to update the querybuilder tree on change
     */
    function onChange(immutableTree, config) {
        setTree(immutableTree);
        setConfig(config);
        props.updateSearchQueryForParentComponent(immutableTree);
        setSearchParams({ query: JSON.stringify(immutableTree) });

        // `jsonTree` or `logic` can be saved to backend
        // (and then loaded with `loadTree` or `loadFromJsonLogic` as seen above)
        // eslint-disable-next-line
        const jsonTree = getTree(immutableTree);

        // eslint-disable-next-line
        const { logic, data, errors } = jsonLogicFormat(immutableTree, config);
    }


    return (
        <div className="main--container">
            <Query
                {...config}
                value={tree}
                onChange={onChange}
                renderBuilder={renderBuilder}
            />
            <div className="main--buttons">
                <div className="main--submit">
                    <button onClick={handleSubmit}>Submit Query</button>
                </div>
                <div className="main--reset-clear">
                    <button onClick={resetValue}>Reset Query</button>
                    <button onClick={clearValue}>Clear Query</button>
                </div>
            </div>
            {!done ? (<BigLoading />) : (
            <div className="main--results">
                <div className="results--container">
                    <hr />
                    { results.length > 0 ? (
                        <div>
                            <div>
                                <ButtonGroup variant="outlined" size="large" aria-label="large button group">
                                    <Button onClick={handlePrevPageClick} disabled={page === 1}> Prev</Button>
                                    <Button onClick={handleNextPageClick} disabled={page === numPages}> Next</Button>
                                </ButtonGroup>          
                                {numPages !== 0 &&  <h3 style={{ marginLeft: "20px"}}>Page {page} of {numPages} - {numResults} results</h3>}
                                {!changePageDone && <SmallLoading />}
                            </div>
                            
                            <div className='item-container'>
                                
                                {results.map((result) => (                          
                                    <div key={result._id} onClick={e => handleEffectClick(result._source.django_id)}>
                                        <Box 
                                            sx={{ 
                                                overflow: 'auto',
                                                flexGrow: 1,
                                                textAlign: 'center',
                                                padding: '1em',
                                                width: '100%',
                                                borderRadius: '5px',
                                                border: 1,
                                                borderColor: 'grey.300',
                                                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                '&:hover': {
                                                    backgroundColor: 'hsl(0, 0%, 90%)',
                                                },
                                            }} 
                                        >
                                            <Typography
                                                variant="h5" 
                                                style={{textAlign: 'left', marginBottom: '16px'}}
                                            >{result._source.effect} ({result._source.perception} - {result._source.grouping} grouping)</Typography>
                                            <Grid 
                                                container 
                                                spacing={2}
                                                justifyContent="space-evenly"
                                                alignItems="flex-start"
                                            >
                                                <Grid item xs={4} align="left">
                                                    <Typography><b>{result._source.work}</b></Typography>
                                                    <Typography>Composed: <b>{result._source.composition_year}</b></Typography>
                                                    <Typography>Strength: <b>{result._source.strength}</b></Typography>
                                                    <Typography>Duration: <b>{result._source.duration}</b></Typography>
                                                    <Typography>Evolution: <b>{result._source.evolution}</b></Typography>
                                                    <Typography>mm: <b>{result._source.first_bar}-{result._source.last_bar}</b></Typography>
                                                </Grid>
                                                <Grid item xs={4} align="left">                                                        
                                                    <Typography>Instrumentation: {result._source.instruments.join(", ")}</Typography>
                                                </Grid>
                                                <Grid item xs={4} align="left">
                                                    { result._source.notes &&
                                                        <Typography>Notes: {result._source.notes}</Typography> 
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </div>
                                    
                                ))}
                            </div>
                            <div className="search--results-header">
                                <ButtonGroup className="search--results-page" variant="outlined" size="large" aria-label="large button group">
                                    <Button onClick={handlePrevPageClick} disabled={page === 1}> Prev</Button>
                                    <Button onClick={handleNextPageClick} disabled={page === numPages}> Next</Button>
                                </ButtonGroup>          
                                {numPages !== 0 &&  <h3 style={{ marginLeft: "20px"}}>Page {page} of {numPages} - {numResults} results</h3>}
                                {!changePageDone && <SmallLoading />}
                            </div>  
                        </div>
                    ):(
                        <p className="results--content">No results found.</p>
                    )}
                                
                    <hr />
                </div>
            </div>)}
        </div>
    )

    // Effect navigation function
    function handleEffectClick(django_id){
        navigate(`/effect/${django_id}`);
    }

    // Page navigation functions
    function handleNextPageClick() {
        setChangePageDone(false);
        setPage(page + 1);   
    }
    function handlePrevPageClick() {
        setChangePageDone(false);
        setPage(page - 1);
    }
}