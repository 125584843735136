import React, { useState } from 'react';
import "./Profile.css";
import { Container, Row, Col } from 'react-bootstrap';
// MUI imports
import { Box, Tab, Paper } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
// Component imports
import ProfileTab from './ProfileTab';
import QueryList from './QueryList';
import ParsingHistory from './ParsingHistory';

export default function ProfilePage() {    
    const [tab, setTab] = useState('1');
    const [userName, setUserName] = useState("");

    const handleProfileTabUserNameData = (childUserName) => {
        setUserName(childUserName);
    };
       
    // handle tabs
    const handleTabChange = (event, newTab) => {
        setTab(newTab);
    };

    return (
        <div className="profile--container">
            <header className="profile--header">
                <h1>My Profile</h1>
            </header>               
            <div className="profile--content">
                <Paper elevation={3} style={{ padding: '20px', height:'70vh', backgroundColor: 'white' }}>
                    <Container>
                        <Row>
                            <Col>
                                <h2>Welcome {userName}</h2>
                            </Col>
                        </Row>
                    </Container>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={tab}>
                            <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
                                <TabList onChange={handleTabChange} indicatorColor='Mui-selected'>
                                    <Tab label={<span><b>Profile</b></span>} value="1" />
                                    <Tab label={<span><b>Saved Queries</b></span>} value="2" />
                                    {localStorage.getItem("role") === 'staff' && <Tab label={<span><b>Parsing History</b></span>} value="3" />}       
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <ProfileTab 
                                    sendUserNameDataToParent={handleProfileTabUserNameData}
                                />
                            </TabPanel>
                            <TabPanel value="2"><QueryList /></TabPanel>
                            {localStorage.getItem("role") === 'staff' && <TabPanel value="3"><ParsingHistory /></TabPanel>}
                        </TabContext>
                    </Box>
                </Paper>
            </div>          
        </div>       
    );
}