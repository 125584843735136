import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import env from '../../env/env';
import "./Orchestras.css";
import Spinner from "../core/spinner/Spinner";
import { Table, TableBody, TableCell, TableHead, TableRow, 
         TableContainer, Paper, Stack, Button, Typography} from "@mui/material"

export default function Orchestras() {

    const [orchestras, setOrchestras] = useState([]);
    const [done, setDone] = useState(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(env.BACKEND_API_URL + '/api/orchestra/').then((res) => {           
            setOrchestras(res.data.results);
            setDone(true);  
        })
    }, []);

    function handleMovementClick(id) {
        navigate(`/movements/${id}`);
    }

    return(
        <div className="orchestras--content">
            <header className="orchestras--header">
                <h1>Orchestras</h1>
            </header>
            { !done ? (
                <Spinner />  
            ):(
                <div className="orchestras--main">
                    <TableContainer component={Paper} sx={{width: "90%"}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontSize: '24px', fontWeight: "600"}}>Orchestra</TableCell>
                                    <TableCell align="center" sx={{ fontSize: '24px', fontWeight: "600"}}>Movements</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    orchestras.map((orchestra, i) => {
                                        return (
                                            <TableRow key={i}>
                                                <TableCell style={{verticalAlign:"top"}}>
                                                    <Typography 
                                                        variant="h6"
                                                        align="left"
                                                    >
                                                        {orchestra.title}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Stack spacing={1} align="center">
                                                        {
                                                            orchestra.recordings.map((recording, j)=> {
                                                                return(
                                                                    <div key={j} sx={{textAlign:"center"}}>
                                                                        <Button variant="text" onClick={e => handleMovementClick(recording.id)}>
                                                                            <b>{recording.title}</b>
                                                                        </Button>                                                                        
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}                    
        </div>
    )
}