import React, { useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import env from '../../env/env';
import "./Movements.css";
import Spinner from "../core/spinner/Spinner";
import SmallLoading from "../core/spinner/small-loading/small-loading";
import { Table, TableBody, TableCell, TableContainer, 
         TableHead, TableRow, Paper, Button, Box, InputLabel, 
         MenuItem, FormControl, Select, ButtonGroup, Modal, 
         Typography } from "@mui/material";


export default function Movements() {

    const [movements, setMovements] = useState(undefined);
    const [done, setDone] = useState(undefined);
    
    // Info Popup
    const [InfoPopupOpen, setInfoPopupOpen] = useState(false);
    const handleInfoModalOpen = () => setInfoPopupOpen(true);
    const handleInfoModalClose = () => setInfoPopupOpen(false);
    const infoModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px',
        overflow: 'hidden',
        overflowY: "scroll",
        maxHeight: '100%'
    };

    // pagination
    const [numResults, setNumResults] = useState(undefined);
    const [page, setPage] = useState(1);
    const [numPages, setNumPages] = useState(undefined);
    const [changePageDone, setChangePageDone] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        try {
            axios.get(env.BACKEND_API_URL + '/api/movement/?page=' + page).then((res) => {
                setMovements(res.data.results);
                setNumResults(res.data.count);
                setNumPages(Math.ceil(res.data.count / 10));
                setDone(true);
                setChangePageDone(true);
            });    
        } catch (error) {
            console.error('Error:', error.message);
    
            if (error.response && (error.response.status === 401 || error.response.status === 406)) {
              navigate('./login'); 
            }
        }
    // eslint-disable-next-line
    }, [page]);

    // Page navigation functions
    function handleNextPageClick() {
        setChangePageDone(false);
        setPage(page + 1);   
    }
    function handlePrevPageClick() {
        setChangePageDone(false);
        setPage(page - 1);
    }

    function handleEffectClick(id) {
        navigate(`/effect/${id}`);
    }

    function handleMovementClick(id) {
        navigate(`/movements/${id}`);
    }

    return(
        <div className="movements--content">
            <header className="movements--header">
                <h1>Movements</h1>
            </header>
            { !done ? (
                <Spinner />  
            ):(
                <div className="movements--main">
                    <TableContainer component={Paper} style={{padding: '20px'}}>
                        <Button 
                            variant='outlined'
                            color="warning" 
                            onClick={handleInfoModalOpen}
                            sx={{marginBottom:'1em'}}
                        >
                            <b>Effect Abbrevations</b>
                        </Button>
                        <Modal
                            open={InfoPopupOpen}
                            onClose={handleInfoModalClose}
                        >
                            <Box sx={infoModalStyle}>
                                <Typography variant="h5" align="center">Effect Abbrevations</Typography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center"><b>Effect</b></TableCell>
                                            <TableCell align="center"><b>Abbrevation</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center">Timbral Augmentation</TableCell>
                                            <TableCell align="center">TA</TableCell>
                                        </TableRow>
                                        <TableRow> 
                                            <TableCell align="center">Timbral Emergence</TableCell>
                                            <TableCell align="center">TM</TableCell>
                                        </TableRow>
                                        <TableRow> 
                                            <TableCell align="center">Timbral Heterogeneities</TableCell>
                                            <TableCell align="center">TH</TableCell>
                                        </TableRow>
                                        <TableRow> 
                                            <TableCell align="center">Stratifications</TableCell>
                                            <TableCell align="center">SF</TableCell>
                                        </TableRow>
                                        <TableRow> 
                                            <TableCell align="center">Streams</TableCell>
                                            <TableCell align="center">SS</TableCell>
                                        </TableRow>
                                        <TableRow> 
                                            <TableCell align="center">Surface Textures</TableCell>
                                            <TableCell align="center">ST</TableCell>
                                        </TableRow>
                                        <TableRow> 
                                            <TableCell align="center">Stream</TableCell>
                                            <TableCell align="center">SM</TableCell>
                                        </TableRow>
                                        <TableRow> 
                                            <TableCell align="center">Antiphonal Contrasts</TableCell>
                                            <TableCell align="center">AC</TableCell>
                                        </TableRow>
                                        <TableRow> 
                                            <TableCell align="center">Timbral Echoes</TableCell>
                                            <TableCell align="center">TC</TableCell>
                                        </TableRow>
                                        <TableRow> 
                                            <TableCell align="center">Timbral Shifts</TableCell>
                                            <TableCell align="center">TS</TableCell>
                                        </TableRow>
                                        <TableRow> 
                                            <TableCell align="center">Timbral Juxtapositions</TableCell>
                                            <TableCell align="center">TJ</TableCell>
                                        </TableRow>
                                        <TableRow> 
                                            <TableCell align="center">Sectional Contrasts</TableCell>
                                            <TableCell align="center">SC</TableCell>
                                        </TableRow>
                                        <TableRow> 
                                            <TableCell align="center">Orchestral Gestures</TableCell>
                                            <TableCell align="center">OG</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Modal>
                        <div>
                            <ButtonGroup variant="outlined" size="large" aria-label="large button group">
                                <Button onClick={handlePrevPageClick} disabled={page === 1}> Prev</Button>
                                <Button onClick={handleNextPageClick} disabled={page === numPages}> Next</Button>
                                     
                                {numPages !== 0 &&  <h3 style={{ marginLeft: "20px", marginTop: "8px"}}>Page {page} of {numPages} - {numResults} results</h3>}
                                {!changePageDone && <SmallLoading />}
                            </ButtonGroup> 
                        </div>
                        <Table stickyHeader>
                            <TableHead > 
                                <TableRow>
                                    <TableCell className="movements--tablehead" align="center" width="400px" sx={{ fontSize: '20px', fontWeight: "600"}}> Movement</TableCell>
                                    <TableCell className="movements--tablehead" align="center" sx={{ fontSize: '20px', fontWeight: "600"}}>Blend</TableCell>
                                    <TableCell className="movements--tablehead" align="center" sx={{ fontSize: '20px', fontWeight: "600"}}>Non Blend</TableCell>
                                    <TableCell className="movements--tablehead" align="center" sx={{ fontSize: '20px', fontWeight: "600"}}>Segregation</TableCell> 
                                    <TableCell className="movements--tablehead" align="center" sx={{ fontSize: '20px', fontWeight: "600"}}>Integration</TableCell> 
                                    <TableCell className="movements--tablehead" align="center" sx={{ fontSize: '20px', fontWeight: "600"}}>Timbral Contrast</TableCell>  
                                    <TableCell className="movements--tablehead" align="center" sx={{ fontSize: '20px', fontWeight: "600"}}>Timbral Progression</TableCell> 
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {movements.map((movement) => (
                                    <TableRow
                                        key={movement.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <Button onClick={e => handleMovementClick(movement.id)}>
                                                <b>{movement.name}</b>
                                            </Button>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box sx={{ minWidth: 120, maxWidth: 120 }}>
                                                <FormControl fullWidth size="small" disabled={movement.effects["BL"].count === 0}>
                                                    <InputLabel id="demo-simple-select-label">{movement.effects["BL"].count} results</InputLabel>
                                                    <Select
                                                        label="blends"
                                                        defaultValue=""
                                                    >
                                                        {movement.effects["BL"].effect_list.map((effect) => {
                                                            return(
                                                                <MenuItem key={effect.id} onClick={e => handleEffectClick(effect.id)}
                                                                    sx={{maxWidth: 200}}
                                                                >{effect.effect_type_short} m.m {effect.first_bar}-{effect.last_bar}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box sx={{ minWidth: 120, maxWidth: 120 }}>
                                                <FormControl fullWidth size="small" disabled={movement.effects["NB"].count === 0}>
                                                    <InputLabel id="demo-simple-select-label">{movement.effects["NB"].count} results</InputLabel>
                                                    <Select
                                                        label="segregation"
                                                        defaultValue=""
                                                    >
                                                        {movement.effects["NB"].effect_list.map((effect) => {
                                                            return(
                                                                <MenuItem key={effect.id} onClick={e => handleEffectClick(effect.id)}
                                                                    sx={{maxWidth: 200}}
                                                                >{effect.effect_type_short} m.m {effect.first_bar}-{effect.last_bar}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box sx={{ minWidth: 120, maxWidth: 120 }}>
                                                <FormControl fullWidth size="small" disabled={movement.effects["SE"].count === 0}>
                                                    <InputLabel id="demo-simple-select-label">{movement.effects["SE"].count} results</InputLabel>
                                                    <Select
                                                        label="stratification"
                                                        defaultValue=""
                                                    >
                                                        {movement.effects["SE"].effect_list.map((effect) => {
                                                            return(
                                                                <MenuItem key={effect.id} onClick={e => handleEffectClick(effect.id)}
                                                                    sx={{maxWidth: 200}}
                                                                >{effect.effect_type_short} m.m {effect.first_bar}-{effect.last_bar}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box sx={{ minWidth: 120, maxWidth: 120 }}>
                                                <FormControl fullWidth size="small" disabled={movement.effects["IN"].count === 0}>
                                                    <InputLabel id="demo-simple-select-label">{movement.effects["IN"].count} results</InputLabel>
                                                    <Select
                                                        label="progressive"
                                                        defaultValue=""
                                                    >
                                                       {movement.effects["IN"].effect_list.map((effect) => {
                                                            return(
                                                                <MenuItem key={effect.id} onClick={e => handleEffectClick(effect.id)}
                                                                    sx={{maxWidth: 200}}
                                                                >{effect.effect_type_short} m.m {effect.first_bar}-{effect.last_bar}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box sx={{ minWidth: 120, maxWidth: 120 }}>
                                                <FormControl fullWidth size="small" disabled={movement.effects["TC"].count === 0}>
                                                    <InputLabel id="demo-simple-select-label">{movement.effects["TC"].count} results</InputLabel>
                                                    <Select
                                                        label="contrast"
                                                        defaultValue=""
                                                    >
                                                        {movement.effects["TC"].effect_list.map((effect) => {
                                                            return(
                                                                <MenuItem key={effect.id} onClick={e => handleEffectClick(effect.id)}
                                                                    sx={{maxWidth: 200}}
                                                                >{effect.effect_type_short} m.m {effect.first_bar}-{effect.last_bar}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box sx={{ minWidth: 120, maxWidth: 120 }}>
                                                <FormControl fullWidth size="small" disabled={movement.effects["TP"].count === 0}>
                                                    <InputLabel id="demo-simple-select-label">{movement.effects["TP"].count} results</InputLabel>
                                                    <Select
                                                        label="gesture"
                                                        defaultValue=""
                                                    >
                                                        {movement.effects["TP"].effect_list.map((effect) => {
                                                            return(
                                                                <MenuItem key={effect.id} onClick={e => handleEffectClick(effect.id)}
                                                                    sx={{maxWidth: 200}}
                                                                >{effect.effect_type_short} m.m {effect.first_bar}-{effect.last_bar}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>    
                    </TableContainer>
                    
                </div>
            )}          
        </div>
    )
}