import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import env from "../../env/env";
import "./Parser.css";
import Spinner from "../core/spinner/Spinner";
//MUI Imports
import {
  Button,
  Alert,
  Collapse,
  Modal,
  Box,
  Tab,
  IconButton,
  Paper,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
// Child components imports
import AnnotationList from "./annotation-list/AnnotationList";
import ErrorList from "./error-list/ErrorList";
import MetaData from "./meta-data/MetaData";
import InstrumentList from "./instrument-list/InstrumentList";

const parserStatus = {
  FILESUBMISSION: 0,
  LOADINGRESULTS: 1,
  RESULTS: 2,
  LOADINGUPLOAD: 3,
};


export default function Parser() {
  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState("");
  // tab state
  const [tab, setTab] = useState("1");
  // drag state
  const [dragActive, setDragActive] = useState(false);
  // data state
  const [annotationList, setAnnotationList] = useState([]);
  const [metaData, setMetaData] = useState([]);
  const [metaDataErrorsList, setMetaDataErrorsList] = useState([]);
  const [annotationErrorsList, setAnnotationErrorsList] = useState([]);
  const [instrumentList, setInstrumentList] = useState([]);
  const [annotationsLength, setAnnotationsLength] = useState(0);
  const [totalErrorsLength, setTotalErrorsLength] = useState(0);
  // 0 : Parsing not initiated, 1 : Parsing in progress, 2 : Parsing done, 4 : Saving in progress
  const [parsingStatus, setParsingStatus] = useState(parserStatus.FILESUBMISSION);
  // ref
  const inputRef = useRef(null);
  // alerts
  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertFailureOpen, setAlertFailureOpen] = useState(false);
  // modal
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  
  // Tabs handlers
  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

  // handle drag events
  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileChange(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange = function(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFileChange(e.target.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = function(e) {
    e.preventDefault();
    inputRef.current.click();
  };

  // File upload handlers
  const handleFileChange = (file_from_event) => {
    const file = file_from_event;

    // Check if the selected file is an XML file
    if (file && file.type === "text/xml") {
      setFileError("");
      setSelectedFile(file);
    } else {
      setFileError("Invalid file format. Please select an XML file.");
      setSelectedFile(null);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setParsingStatus(parserStatus.LOADINGRESULTS);

    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append("file", selectedFile);

        // Make the Axios POST request
        await axios
          .post(env.BACKEND_API_URL + "/api/parser/parse/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.response?.status === 0 || res.response?.status === 500) {
              throw new Error(
                "An unexpected error occurred while uploading the file. Please contact the admins to notify them about this issue."
              );
            }
            if (res.response?.status === 400) {
              throw new Error(res.response.data);
            }
            setAnnotationList(res.data.annotationResults);
            setAnnotationsLength(res.data.annotationResults.length);
            setMetaData(res.data.metaResults);
            setMetaDataErrorsList(res.data.metaErrors);
            setTotalErrorsLength(
              res.data.metaErrors.length + res.data.annotationErrors.length
            );
            setAnnotationErrorsList(res.data.annotationErrors);
            setInstrumentList(res.data.instrumentList);

            setTab("1");
            setParsingStatus(parserStatus.RESULTS);
            setFileError(null);
            setAlertSuccessOpen(false);
            setAlertFailureOpen(false);
          });
      } catch (error) {
        setParsingStatus(parserStatus.FILESUBMISSION);
        setFileError(String(error));

        if (error.response && error.response.status === 401) {
          navigate("./login");
        }
      }
    }
  };

  // reset the parser to the initial state
  const handleReset = () => {
    setParsingStatus(parserStatus.FILESUBMISSION);

    // reset every list from API Call
    setAnnotationList([]);
    setAnnotationsLength(0);
    setMetaData([]);
    setMetaDataErrorsList([]);
    setAnnotationErrorsList([]);
    setTotalErrorsLength(0);
    setInstrumentList(0);
    setModalOpen(false);
  };

  // handle saving
  const saveParse = () => {
    setParsingStatus(parserStatus.LOADINGUPLOAD);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("annotations", JSON.stringify(annotationList));
    formData.append("metadata", JSON.stringify(metaData));

    axios
      .post(env.BACKEND_API_URL + "/api/parser/save_parse/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res === undefined || res.status !== 200) {
          throw new Error("Unsuccessful parse save.");
        }
        setAlertSuccessOpen(true);
        setSelectedFile(null);
        handleReset();
      })
      .catch(() => {
        setParsingStatus(parsingStatus.FILESUBMISSION);
        setAlertFailureOpen(true);
        handleReset();
      });
  };

  function FileSubmission() {
    return (
      <div>
        <Collapse in={alertSuccessOpen}>
          <Alert
            variant="standard"
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertSuccessOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            Successfully saved the annotations!
          </Alert>
        </Collapse>
        <Collapse in={alertFailureOpen}>
          <Alert
            variant="standard"
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertFailureOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            Sorry, we were not able to save the annotations. Please, try again
            later or contact the admins.
          </Alert>
        </Collapse>
        <form id="form-file-upload" onDragEnter={handleDrag}>
          <input
            ref={inputRef}
            type="file"
            id="input-file-upload"
            multiple={true}
            onChange={handleChange}
          />
          <label
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={dragActive ? "drag-active" : ""}
          >
            <div>
              <p>Drag and drop your xml file here or</p>
              <button className="upload-button" onClick={onButtonClick}>
                Click here to upload {selectedFile ? "another" : "a"} file
              </button>
              {selectedFile && <h4>Selected file: {selectedFile.name}</h4>}
            </div>
          </label>
          {dragActive && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            />
          )}
          {fileError && (
            <Alert variant="standard" severity="error" sx={{ mt: "1em" }}>
              {fileError}
            </Alert>
          )}
          <br />
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedFile}
            onClick={handleSubmit}
          >
            <b>Parse the file</b>
          </Button>
        </form>
      </div>
    );
  }

  return (
    <div className="parser--container">
      <header className="parser--header">
        <h1>Parser</h1>
      </header>
      <div className="main" />
      <div className="parser--content">
        {parsingStatus === parserStatus.FILESUBMISSION && <FileSubmission />}
        {parsingStatus === parserStatus.LOADINGRESULTS && <Spinner />}
        {parsingStatus === parserStatus.LOADINGUPLOAD && (
          <div>
            <Paper
              elevation={3}
              style={{ padding: "20px", backgroundColor: "white" }}
            >
              <h3>*Please wait until the new data is loaded into OrchARD.</h3>
              <h3>*This operation could take several minutes.</h3>
              <Spinner />
            </Paper>
          </div>
        )}
        {parsingStatus === parserStatus.RESULTS && (
          <div>
            <Paper
              elevation={3}
              style={{ padding: "20px", backgroundColor: "white" }}
            >
              <Button
                style={{ marginRight: "10px" }}
                variant="contained"
                color="primary"
                onClick={handleReset}
              >
                <b>Parse another file</b>
              </Button>
              <Button
                variant="contained"
                color="success"
                disabled={totalErrorsLength !== 0}
                onClick={handleModalOpen}
              >
                <b>Save annotations</b>
              </Button>
              <br />
              <br />
              <h3>{selectedFile.name}</h3>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={tab}>
                  <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
                    <TabList
                      onChange={handleTabChange}
                      indicatorColor="Mui-selected"
                    >
                      <Tab
                        label={
                          <span>
                            <b>Annotations ({annotationsLength})</b>
                          </span>
                        }
                        value="1"
                      />
                      <Tab
                        label={
                          <span>
                            <b>Meta Data</b>
                          </span>
                        }
                        value="2"
                        disabled={metaDataErrorsList.length > 0}
                      />
                      <Tab
                        label={
                          <span>
                            <b>Instruments</b>
                          </span>
                        }
                        value="3"
                      />
                      <Tab
                        label={
                          <span>
                            <b>
                              {totalErrorsLength === 0
                                ? "Errors"
                                : "Errors (" +
                                  totalErrorsLength.toString() +
                                  ")"}
                            </b>
                          </span>
                        }
                        value="4"
                        disabled={totalErrorsLength === 0}
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <AnnotationList annotationList={annotationList} />
                  </TabPanel>
                  <TabPanel value="2">
                    {metaDataErrorsList.length === 0 && (
                      <MetaData metaData={metaData} />
                    )}
                  </TabPanel>
                  <TabPanel value="3">
                    <InstrumentList instrumentList={instrumentList} />
                  </TabPanel>
                  <TabPanel value="4">
                    <ErrorList
                      metaDataErrorsList={metaDataErrorsList}
                      annotationErrorsList={annotationErrorsList}
                    />
                  </TabPanel>
                </TabContext>
              </Box>
            </Paper>
            <Modal open={modalOpen} onClose={handleModalClose}>
              <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 500,
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                  borderRadius: '8px'
              }}>
                <h3>You are about to add new data into the database.</h3>
                <h3>
                  Please make sure that the new data was correctly parsed.
                </h3>
                <Button
                  variant="contained"
                  color="success"
                  onClick={saveParse}
                  sx={{ mt: "1em" }}
                >
                  <b>Save The Parsed Data</b>
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleModalClose}
                  sx={{ ml: "1em", mt: "1em" }}
                >
                  <b>Cancel</b>
                </Button>
              </Box>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
}
