import React, { useState } from 'react';
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { Button, Grid, TextField, Alert, Collapse, Typography, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../../images/treeimg.png';
import "./PasswordReset.css";
import env from '../../../env/env';

export default function PasswordResetConfirm() {
    const {uid, token} = useParams();
    const [resetDone, setResetDone] = useState(false);

    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

    const [alertFailureOpen, setAlertFailureOpen] = useState(false);
    const [failureMessage, setFailureMessage] = useState(undefined);

    const handleSubmit = (event) => {
        // API call to reset the password
        axios.post(env.BACKEND_API_URL + '/api/accounts/password_confirm/', {
            new_password: newPassword,
            new_password_confirm: newPasswordConfirm,
            user_id: uid,
            reset_token: token
        })
            .then((res) => {
                if(res.status !== 200) {
                    if (res.response.data?.detail) {
                        throw new Error(res.response.data.detail);
                    } else if (res.response.data.new_password) {
                        throw new Error(res.response.data.new_password);
                    } else {
                        throw new Error(res.response.data);
                    }                   
                }
                setAlertFailureOpen(false);
                setFailureMessage("");
                setResetDone(true);

            }).catch(error => {
                setFailureMessage(String(error));                
                setAlertFailureOpen(true);
            });
    }

    return(
        <div>
            <div className="hp--container">
                <div className="hp--title">
                    <div className="hp--title-text">
                        <h2><span className="title--1">O</span><span className="title--2">RCH</span><span className="title--3">ESTRATION</span></h2>
                        <h2><span className="title--1">A</span><span className="title--3">NALYSIS &</span></h2>
                        <h2><span className="title--1">R</span><span className="title--3">ESEARCH</span></h2>
                        <h2><span className="title--1">D</span><span className="title--3">ATABASE</span></h2>
                    </div>
                    <img src={logo} alt="orchard logo" />
                </div>
                { !resetDone ? (
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        >
                            <Grid item xs={3}>
                                    <Typography align="center">
                                        <b>Forgotten your password?</b>
                                    </Typography>
                                    <Typography align="center">
                                        <b>Enter your email address below, and we will email</b>
                                    </Typography>
                                    <Typography align="center">
                                        <b>instructions for setting a new password if an account is </b>
                                    </Typography>
                                    <Typography align="center">
                                        <b>linked to this email</b>
                                    </Typography>
                                    <Collapse in={alertFailureOpen}>
                                        <Alert
                                            variant="standard" severity="error"
                                            action={
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        setAlertFailureOpen(false);
                                                    }}
                                                >
                                                <CloseIcon fontSize="inherit" />
                                                </IconButton>
                                            }
                                            sx={{ mb: 2 }}
                                            >
                                            {failureMessage}
                                        </Alert>
                                    </Collapse>
                                    <TextField 
                                        required
                                        fullWidth
                                        margin="normal"
                                        label="New Password"
                                        name="new_password"
                                        type="password"
                                        sx={{width: "100%"}}
                                        value={newPassword}
                                        onChange={e => setNewPassword(e.target.value)}

                                    />
                                    <TextField
                                        required
                                        fullWidth
                                        margin="normal"
                                        label="Confirm New Password"
                                        name="confirm_new_password"
                                        type="password"
                                        value={newPasswordConfirm}
                                        onChange={e => setNewPasswordConfirm(e.target.value)}
                                        sx={{width: "100%"}}
                                        helperText={newPassword !== newPasswordConfirm && 'Password fields do not match!'}
                                    />
                                    <Button 
                                        align="center"
                                        variant="contained" 
                                        onClick={handleSubmit} 
                                        disabled={(newPassword !== newPasswordConfirm)||!newPassword}
                                        sx={{ mt: '1em'}}
                                    ><b>Reset Password</b></Button>
                            </Grid>
                    </Grid>
                ) : (
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        >
                            <Grid item xs={3}>
                                <Typography align="center">
                                    <b>Password Reset Successful!</b>
                                    <br />
                                    <Link to="/login">Go to login</Link>
                                </Typography>
                            </Grid>
                    </Grid>
                )}
            </div>
        </div>
    );
}
