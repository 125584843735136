import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import logo from '../../../images/treeimg.png';
import "./Navbar.css";
import env from '../../../env/env';

export default function Navbar() {

    const [username, setUsername] = React.useState("");

    React.useEffect(() => {
        axios.get(env.BACKEND_API_URL + '/api/accounts/profile/')
            .then((res) => {
                setUsername(res.data.username);
            })
            .catch(() => {
                setUsername("");
                localStorage.clear()
                localStorage.setItem('username', "")
                localStorage.setItem('role', "")
            });
    }, []);

    function handleSignOut() {
        axios.post(env.BACKEND_API_URL + '/api/accounts/logout/', {}, { withCredentials: true })
            .then((res) => {
                if (res.status === 200) {
                    localStorage.clear()
                    localStorage.setItem('username', "")
                    localStorage.setItem('role', "")
                    window.location.reload();
                    window.location.href = "/";              
                }
            })
            .catch(() => {        
                // TODO redirect to server error page
            });
    }

    return (
        <nav className="navbar">
            <div className="navbar--title">
                <Link to="/" className="title--link">ORCH.A.R.D.</Link>
                <img src={logo} alt="orchard logo" className="navbar--img" />
            </div>
            <hr />
            <ul className="navbar--list">
                {/* <li><Link to="/querybuilder">QueryBuilder Search</Link></li> */}
                {/* Querybuilder dropdown */}
                <li className="primary-dropdown">
                    <div className="primary-dropdown--title">
                        <span>Query Builder</span>
                        <div className="primary-dropdown--triangle" />
                    </div>
                    <div className="primary-dropdown--menu querybuilder">
                        <Link to="/querybuilder">Search</Link>
                        <Link to="/querybuilder/tutorial">Tutorial</Link>
                    </div>
                </li>
                {/* End querybuilder dropdown */}
                {/* Start effects dropdown */}
                <li className="primary-dropdown">
                    <div className="primary-dropdown--title">
                        <span>Orchestral Grouping Effects</span>
                        <div className="primary-dropdown--triangle" />
                    </div>
                    <div className="primary-dropdown--menu effects">
                        <Link to="/effects">All Orchestral Grouping Effects</Link>
                        <div className="secondary-dropdown">
                            <div className="secondary-dropdown--title">
                                <span>Concurrent Grouping</span>
                                <div className="secondary-dropdown--triangle" />
                            </div>
                            <div className="secondary-dropdown--menu concurrent">
                                <Link to="/concurrent-groupings">All Concurrent Groupings</Link>
                                <span>Blend</span>
                                <Link to="/blends">All Blends</Link>
                                <Link to="/timbral-augmentations">Timbral Augmentations</Link>
                                <Link to="/timbral-emergences">Timbral Emergences</Link>
                                <span>Non-Blend</span>
                                <Link to="/non-blends">All Non-Blends</Link>
                                <Link to="/timbral-heterogeneities">Timbral Heterogeneities</Link>
                            </div>
                        </div>
                        <div className="secondary-dropdown">
                            <div className="secondary-dropdown--title">
                                <span>Sequential Grouping</span>
                                <div className="secondary-dropdown--triangle" />
                            </div>
                            <div className="secondary-dropdown--menu sequential">
                                <Link to="/sequential-groupings">All Sequential Groupings</Link>
                                <span>Segregation</span>
                                <Link to="/segregations">All Segregations</Link>
                                <Link to="/stratifications">Stratifications</Link>
                                <Link to="/streams">Streams</Link>
                                <span>Integration</span>
                                <Link to="/integrations">All Integrations</Link>
                                <Link to="/surface-textures">Surface Textures</Link>
                                <Link to="/stream">Stream</Link>
                            </div>
                        </div>
                        <div className="secondary-dropdown">
                            <div className="secondary-dropdown--title">
                                <span>Segmental Grouping</span>
                                <div className="secondary-dropdown--triangle" />
                            </div>
                            <div className="secondary-dropdown--menu segmental">
                                <Link to="/segmental-groupings">All Segmental Groupings</Link>
                                <span>Timbral Contrasts</span>
                                <Link to="/timbral-contrasts">All Timbral Contrasts</Link>
                                <Link to="/antiphonal-contrasts">Antiphonal Contrasts</Link>
                                <Link to="/timbral-echoes">Timbral Echoes</Link>
                                <Link to="/timbral-shifts">Timbral Shifts</Link>
                                <Link to="/timbral-juxtapositions">Timbral Juxtapositions</Link>
                                <Link to="/sectional-contrasts">Sectional Contrasts</Link>
                                <span>Timbral Progression</span>
                                <Link to="/timbral-progressions">All Timbral Progressions</Link>
                                <Link to="/orchestral-gestures">Orchestral Gestures</Link>
                            </div>
                        </div>
                    </div>
                </li>
                {/* End effects dropdown */}
                {/* Start models dropdown */}
                <li className="primary-dropdown">
                    <div className="primary-dropdown--title">
                        <span>Models</span>
                        <div className="primary-dropdown--triangle" />
                    </div>
                    <div className="primary-dropdown--menu models">
                        <Link to="/composers">Composers</Link>
                        <Link to="/conductors">Conductors</Link>
                        <Link to="/pieces">Pieces</Link>
                        <Link to="/movements">Movements</Link>
                        <Link to="/orchestras">Orchestras</Link>
                    </div>
                </li>
                {/* End models dropdown */}
                { username && localStorage.getItem("role") === 'staff' && <li><Link to="/parser">Parser</Link></li>}
                { username && localStorage.getItem("role") === 'staff' && <li><Link to="/static_file_upload">Static File Upload</Link></li>}
                <li><a href="https://timbreandorchestration.org/tor/modules/taxonomy/orchestral-grouping-effects/introduction" target="_blank">About</a></li>
                <li><Link to="/feedback">Feedback</Link></li>
                <li>{username ?
                    <Link to="/profile">My Profile</Link> :
                    <Link to="/login">Log in</Link>}
                </li>
                <li>{username ?
                    <button onClick={handleSignOut}>Sign out of {username}</button> :
                    <Link to="/registration">Sign up</Link>}
                </li>
            </ul>
        </nav>
    )
}