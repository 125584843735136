import React from "react";
import { Link } from "react-router-dom"
import "./PageStub.css"

export default function PageStub() {
    return (
        <div className="ps--container">
            <h1>Sorry, this page is currently unavailable.</h1>
            <h3>Take me back to <Link to="/">the home page</Link>.</h3>
        </div>
    )
}