import React, { useState } from 'react';
import { Select, MenuItem } from '@mui/material';
import { TabPanel, TabContext } from '@mui/lab';
import "./MetaData.css";


export default function MetaData(props) {
    const {metaData} = props;
    const [selectedTab, setSelectedTab] = useState("0");

    const handleDropdownChange = (event) => {
        setSelectedTab(event.target.value);
    };

    return (
        <div>
            <div>
                <Select 
                    value={selectedTab} 
                    onChange={handleDropdownChange}
                    style={{minWidth:"250px"}}
                >
                    <MenuItem value="0">Piece{metaData.piece.new_object ? (" (New)"): ("")}</MenuItem>
                    <MenuItem value="1">Recording{metaData.recording.new_object ? (" (New)"): (" (Replacement)")}</MenuItem>
                    <MenuItem value="2">Score{metaData.score.new_object ? (" (New)"): ("")}</MenuItem>
                    <MenuItem value="3">Movement{metaData.movement.new_object ? (" (New)"): ("")}</MenuItem>
                    <MenuItem value="4">Composer{metaData.composer.new_object ? (" (New)"): ("")}</MenuItem>
                    <MenuItem value="5">Orchestrator{metaData.orchestrator?.new_object ? (" (New)"): ("")}</MenuItem>
                    <MenuItem value="6">Conductor{metaData?.conductor.new_object ? (" (New)"): ("")}</MenuItem>
                    <MenuItem value="7">Orchestra{metaData.orchestra.new_object ? (" (New)"): ("")}</MenuItem>
                    <MenuItem value="8">Annotators
                    {(metaData.annotator1.new_object || metaData.annotator2.new_object) ? (" (New)"): ("")}
                    </MenuItem>
                </Select>
                <TabContext value={selectedTab}>
                    <TabPanel value="0">
                        <h2>Piece</h2>
                        {metaData.piece.new_object && <h4><b>New data!</b></h4>}
                        <ul>
                            <li><b>Title : </b>{metaData.piece.title}</li>
                            <li><b>Composition Year : </b>{metaData.piece.composition_year}</li>
                        </ul>
                    </TabPanel>
                    <TabPanel value="1">
                        <h2>Recording</h2>
                        {metaData.recording.new_object && <h4><b>New data!</b></h4>}
                        <ul>
                            <li><b>Title : </b>{metaData.recording.title}</li>
                            <li><b>Recording Year : </b>{metaData.recording.recording_year}</li>
                            <li><b>Label Name : </b>{metaData.recording.label_name}</li>
                        </ul>
                    </TabPanel>
                    <TabPanel value="2">
                        <h2>Score</h2>
                        <b>New data!</b>
                        <ul>
                            <li><b>Publisher : </b>{metaData.score.publisher}</li>
                            <li><b>Publishing location : </b>{metaData.score.publishing_location}</li>
                            <li><b>Publishing Year : </b>{metaData.score.publishing_year}</li>
                        </ul>
                    </TabPanel>
                    <TabPanel value="3">
                        <h2>Movement</h2>
                        {metaData.movement.new_object && <h4><b>New data!</b></h4>}
                        <ul>
                            <li><b>Title: </b> {metaData.movement.title}</li>
                            <li><b>Number: </b> {metaData.movement.number}</li>
                        </ul>
                    </TabPanel>
                    <TabPanel value="4">
                        <h2>Composer</h2>
                        {metaData.composer.new_object && <h4><b>New data!</b></h4>}
                        <ul>
                            <li><b>Title : </b>{metaData.composer.title}</li>
                            <li><b>First Name : </b>{metaData.composer.first_name}</li>
                            <li><b>Last Name : </b>{metaData.composer.last_name}</li>
                            <li>Birth Country : {metaData.composer.birth_country}</li>
                        </ul>
                    </TabPanel>
                    <TabPanel value="5">
                        {metaData?.orchestrator ? (
                            <div>
                                <h2>Orchestrator</h2>
                                {metaData.orchestrator.new_object && <h4><b>New data!</b></h4>}
                                <ul>
                                    <li><b>Title : </b>{metaData.orchestrator.title}</li>
                                    <li><b>First Name : </b>{metaData.orchestrator.first_name}</li>
                                    <li><b>Last Name : </b>{metaData.orchestrator.last_name}</li>
                                    <li>Birth Country : {metaData.orchestrator.birth_country}</li>
                                </ul>
                            </div>
                        ) : (
                            <div>
                                <h2>Orchestrator</h2>
                                <ul>
                                    <li>The composer and the orchestrator of the piece are the same person.</li>
                                </ul>
                            </div>
                        )}
                    </TabPanel>
                    <TabPanel value="6">
                        <h2>Conductor</h2>
                        {metaData.conductor.new_object && <h4><b>New data!</b></h4>}
                        <ul>
                            <li><b>Title : </b>{metaData.conductor.title}</li>
                            {/* <li><b>First Name : </b>{metaData.conductor.first_name}</li>
                            <li><b>Last Name : </b>{metaData.conductor.last_name}</li> */}
                        </ul>
                    </TabPanel>                   
                    <TabPanel value="7">
                        <h2>Orchestra</h2>
                        {metaData.orchestra.new_object && <h4><b>New data!</b></h4>}
                        <ul>
                            <li><b>Title : </b>{metaData.orchestra.title}</li>
                            <li>Country : {metaData.orchestra.country}</li>
                            <li>City : {metaData.orchestra.city}</li>
                        </ul>
                    </TabPanel>
                    <TabPanel value="8">
                        <h2>Annotator 1 (Created by)</h2>
                        {metaData.annotator1.new_object && <h4><b>New data!</b></h4>}
                        <ul>
                            <li><b>Title : </b>{metaData.annotator1.title}</li>
                        </ul>
                        <br/>
                        <h2>Annotator 2 (Last Updated by)</h2>
                        {metaData.annotator2.new_object && <h4><b>New data!</b></h4>}
                        <ul>
                            <li><b>Title : </b>{metaData.annotator2.title}</li>
                        </ul>
                        <br/>
                    </TabPanel>
                </TabContext>
            </div>
        </div>
    );
}