import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import env from "../../env/env";
import Spinner from "../core/spinner/Spinner";
import "./StaticFileUpload.css";
//MUI Imports
import {
    Alert,
    Button,
    Collapse,
    IconButton,
    Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


const FileUploadStatus = {
    FILESUBMISSION: 0,
    LOADING: 1,
  };

export default function StaticFileUpload() {
    const navigate = useNavigate();

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileError, setFileError] = useState("");
    const [dragActive, setDragActive] = useState(false);
    // ref
    const inputRef = useRef(null);
    // upload status
    const [uploadStatus, setUploadStatus] = useState(FileUploadStatus.FILESUBMISSION);
    // alerts
    const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);

    // handle drag events
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFileChange(e.dataTransfer.files[0]);
        }
    };

    // triggers when file is selected with click
    const handleChange = function(e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFileChange(e.target.files[0]);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = function(e) {
        e.preventDefault();
        inputRef.current.click();
    };

    // File upload handlers
    const handleFileChange = (file_from_event) => {
        const file = file_from_event;

        // Check if the selected file is an XML file
        if (file && (file.type === 'application/zip' || file.type === 'application/x-zip-compressed')) {
            setFileError("");
            setSelectedFile(file);
        } else {
            setFileError("Invalid file format. Please select a zip file.");
            setSelectedFile(null);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setUploadStatus(FileUploadStatus.LOADING);
    
        if (selectedFile) {
          try {
            const formData = new FormData();
            formData.append("file", selectedFile);
    
            // Make the Axios POST request
            await axios
              .post(env.BACKEND_API_URL + "/api/parser/upload_static_files/", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                if (res.response?.status === 0 || res.response?.status === 400 || res.response?.status === 500) {
                  throw new Error(
                    "An unexpected error occurred while uploading the file. Please contact the admins to notify them about this issue."
                  );
                }

                setSelectedFile(null);
                setFileError(null);
                setAlertSuccessOpen(true);
                setUploadStatus(FileUploadStatus.FILESUBMISSION);
              });
          } catch (error) {
            setUploadStatus(FileUploadStatus.FILESUBMISSION);
            setFileError(String(error));
    
            if (error.response && error.response.status === 401) {
              navigate("./login");
            }
          }
        }
      };

    function FileSubmission() {
        return (
            <div>
                <Collapse in={alertSuccessOpen}>
                    <Alert
                        variant="standard"
                        severity="success"
                        action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                            setAlertSuccessOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        The files were successfully saved!
                    </Alert>
                </Collapse>
                <form id="form-file-upload" onDragEnter={handleDrag}>
                    <input
                        ref={inputRef}
                        type="file"
                        id="input-file-upload"
                        multiple={true}
                        onChange={handleChange}
                    />
                    <label
                        id="label-file-upload"
                        htmlFor="input-file-upload"
                        className={dragActive ? "drag-active" : ""}
                    >
                        <div>
                        <p>Drag and drop your zip file here or</p>
                        <button className="upload-button" onClick={onButtonClick}>
                            Click here to upload {selectedFile ? "another" : "a"} file
                        </button>
                        {selectedFile && <h4>Selected file: {selectedFile.name}</h4>}
                        </div>
                    </label>
                    {dragActive && (
                        <div
                        id="drag-file-element"
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                        />
                    )}
                    {fileError && (
                        <Alert variant="standard" severity="error" sx={{ mt: "1em" }}>
                        {fileError}
                        </Alert>
                    )}
                    <br />
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!selectedFile}
                        onClick={handleSubmit}
                    >
                        <b>Upload the static files</b>
                    </Button>
                </form>
            </div>
        );
    }

    return(
        <div className="static-file-upload--container">
            <header className="static-file-upload--header">
                <h1>Static File Upload</h1>
            </header>
            <div className="static-file-upload--content">
                {uploadStatus === FileUploadStatus.FILESUBMISSION && <FileSubmission />}
                {uploadStatus === FileUploadStatus.LOADING && (
                    <div>
                        <Paper
                        elevation={3}
                        style={{ padding: "20px", backgroundColor: "white" }}
                        >
                        <h3>*Please wait until the file is loaded into OrchARD.</h3>
                        <h3>*This operation could take several minutes.</h3>
                        <Spinner />
                        </Paper>
                    </div>
                )}
            </div>
        </div>
    );
}