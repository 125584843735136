import React, { useEffect, useState,  } from 'react';
import axios from 'axios';
import Spinner from "../../core/spinner/Spinner";
import env from '../../../env/env';
// MUI
import { Button, Modal, Box, TextField, Alert, Collapse, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function ProfileTab(props) {
    const [userData, setUserData] = useState(null);
    const [done, setDone] = useState(undefined);

    // Change mail
    const [newEmail, setNewEmail] = useState("");
    const [newEmailDuplicate, setNewEmailDuplicate] = useState("");

    const [emailAlert, setEmailAlert] = useState(undefined);
    const [emailError, setEmailError] = useState("");

    const [changeEmailOpen, setChangeEmailOpen] = useState(false);
    const [changeChildEmailOpen, setChangeChildEmailOpen] = useState(false);
    const handleEmailModalOpen = () => setChangeEmailOpen(true);
    const handleEmailModalClose = () => setChangeEmailOpen(false);


    useEffect(() => {
        getProfile();
    // eslint-disable-next-line   
    }, []); 

    function getProfile() {
        // Make the axios request to fetch user data
        axios.get(env.BACKEND_API_URL + '/api/accounts/profile/')
            .then((res) => {
                setUserData(res.data);
                props.sendUserNameDataToParent(res.data.first_name + " " + res.data.last_name);
                setDone(true);   
            })
    }

    function handleEmailSubmit(event) {
        if  (newEmail === newEmailDuplicate) {

            var bodyFormData = new FormData()
            bodyFormData.append("username", userData.username)
            bodyFormData.append("new_email", newEmail)

            axios.post(env.BACKEND_API_URL + '/api/accounts/email_change/', bodyFormData)
            .then((res) => {
                if(res.status !== 200) {
                    if (res.response.data.new_email) {
                        throw new Error(res.response.data.new_email);
                    } else {
                        throw new Error(res.response.data);
                    }                   
                }

                setChangeChildEmailOpen(true);
                getProfile();
                setNewEmail("");
                setNewEmailDuplicate("");
                setEmailAlert(false);
            })
            .catch((error) => {
                setEmailError(String(error));
                setEmailAlert(true);
            });      
        }
    }


    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px'
      };

    return(
        <div>
            {!done ? (
                <Spinner />
            ):(
                <div>
                    <p><b>Email</b> : {userData.email}</p>
                    <Button 
                        variant="contained"
                        onClick={handleEmailModalOpen}
                        sx={{ mr: '16px' }}
                    ><b>Change Email</b></Button>
                    
                    <Modal
                        open={changeEmailOpen}
                        onClose={handleEmailModalClose}
                    >
                        <Box sx={modalStyle}>
                            <Collapse in={emailAlert}>
                                <Alert
                                    variant="standard" severity="error"
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setEmailAlert(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    sx={{ mb: 2 }}
                                    >
                                        {emailError}
                                </Alert>
                            </Collapse>
                            <TextField
                                required
                                fullWidth
                                margin="normal"
                                label="New Email"
                                name="new_email"
                                type="email"
                                value={newEmail}
                                onChange={e => setNewEmail(e.target.value)}
                                sx={{width: "80%"}}
                            />
                            <TextField 
                                required
                                fullWidth
                                margin="normal"
                                label="Confirm New Email"
                                name="confirm_email"
                                type="email"
                                sx={{width: "80%"}}
                                value={newEmailDuplicate}
                                onChange={e => setNewEmailDuplicate(e.target.value)}
                                helperText={newEmail !== newEmailDuplicate && 'Email fields do not match!'}
                            />
                            <Button 
                                variant="contained" 
                                onClick={handleEmailSubmit} 
                                disabled={!newEmail || !newEmailDuplicate}
                                sx={{ mt: '1em' }}
                            ><b>Submit</b></Button>
                            <EmailChangeModal childOpen={changeChildEmailOpen} setChildOpen={setChangeChildEmailOpen} setParentOpen={setChangeEmailOpen} />
                        </Box>
                    </Modal>
                </div>
            )}
        </div>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

function EmailChangeModal(props) {
    const handleClose = () => {
        props.setChildOpen(false);
        props.setParentOpen(false);
    };

    return (
      <React.Fragment>
        <Modal
          open={props.childOpen}
          onClose={handleClose}
        >
          <Box sx={{ ...style, width: 400 }}>
            <h2 >Your new email has been successfully saved! </h2>
            <Button onClick={handleClose}><b>Close</b></Button>
          </Box>
        </Modal>
      </React.Fragment>
    );
}