import React, {useEffect, useState} from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
/* Components */
import Homepage from "./components/homepage/Homepage";
import Navbar from "./components/core/navbar/Navbar"
import Feedback from "./components/feedback/Feedback";
import PageStub from './components/page-stub/PageStub';
/* User related components */
import Login from "./components/account/login/Login";
import Registration from "./components/account/registration/Registration";
import RegistrationDone from "./components/account/registration/RegistrationDone";
import Activation from './components/account/registration/Activation';
import PasswordReset from './components/account/password-reset/PasswordReset';
import PasswordResetConfirm from './components/account/password-reset/PasswordResetConfirm';
import Profile from './components/account/profile/Profile'; 
/* Effects related components */
import EffectList from './components/effect-list/EffectList';
import EffectDetail from './components/effect-detail/EffectDetail';
/* Search related components */
import GeneralSearch from './components/general-search/GeneralSearch';
import QueryBuilder from './components/querybuilder/Querybuilder';
import TutorialPage from "./components/querybuilder/tutorial-page/TutorialPage";
/* Model related components */
import Composers from "./components/models/Composers";
import Conductors from "./components/models/Conductors";
import Orchestras from './components/models/Orchestras';
import Pieces from './components/models/Pieces';
import PieceDetail from './components/models/piece/PieceDetail';
import Movements from './components/models/Movements';
import MovementDetails from './components/models/movement/MovementDetails';
/* Parser related components */
import Parser from './components/parser/Parser';
import StaticFileUpload from './components/static-file-upload/StaticFileUpload';


export default function App() {
  const [isLoading, setLoading] = useState(true);

  const StaffRoute = ({ children }) => {
    const role = localStorage.getItem('role')
    if (role !== "staff"){
      return <Navigate to="/pagestub"/>
    }
    return children;
  }

  const UserRoute = ({ children }) => {
    const username = localStorage.getItem("username")
    if (!username){
      return <Navigate to="/login"/>
    }
    return children;
  }

  //TODO check implementation
  const AuthenticatedProhibitedRoute = ({ children }) => {
    const username = localStorage.getItem("username")
    if (username){
      return <Navigate to="/"/>
    }
    return children;
  }

  useEffect(() => {
    const loaderElement = document.querySelector(".loader");
    if (loaderElement) {
      loaderElement.remove();
      setLoading(!isLoading);
    }
  }, [isLoading]); 

  if (isLoading) {
    return null;
  }

  
  return (
    <div>
      <BrowserRouter>
        <div className="router">
          <Navbar />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="*" element={<Navigate to="/" />} />

            <Route path="feedback" element={<Feedback />} />
            <Route path="pagestub" element={<PageStub />} />

            {/* Authentication */}
            <Route path="login" element={<AuthenticatedProhibitedRoute><Login /></AuthenticatedProhibitedRoute>} />
            <Route path="registration" element={<AuthenticatedProhibitedRoute><Registration /></AuthenticatedProhibitedRoute>} />
            <Route path="registration_done" element={<AuthenticatedProhibitedRoute><RegistrationDone /></AuthenticatedProhibitedRoute>} />
            <Route path="activation/:username_to_activate" element={<Activation />} />
            <Route path="password-reset" element={<AuthenticatedProhibitedRoute><PasswordReset /></AuthenticatedProhibitedRoute>} />
            <Route path="password-reset-confirm/:uid/:token" element={<AuthenticatedProhibitedRoute><PasswordResetConfirm /></AuthenticatedProhibitedRoute>} />

            <Route path="profile" element={<UserRoute><Profile /></UserRoute>} />

            {/* Parser */}
            <Route path='parser' element={<StaffRoute><Parser /></StaffRoute>} />
            <Route path='static_file_upload' element={<StaffRoute><StaticFileUpload /></StaffRoute>} />

            {/* Querybuilder and General Search*/}
            <Route path="querybuilder" element={<UserRoute><QueryBuilder /></UserRoute>} />
            <Route path="querybuilder/tutorial" element={<TutorialPage />} />
            <Route path="generalsearch" element={<UserRoute><GeneralSearch /></UserRoute>} />
            
            {/* Models */}
            <Route path="composers" element={<UserRoute><Composers /></UserRoute>} />
            <Route path="conductors" element={<UserRoute><Conductors /></UserRoute>} />
            <Route path="orchestras" element={<UserRoute><Orchestras /></UserRoute>} />
            <Route path="pieces" element={<UserRoute><Pieces /></UserRoute>} />
            <Route path="pieces/:id" element={<UserRoute><PieceDetail /></UserRoute>} />
            <Route path="movements" element={<UserRoute><Movements /></UserRoute>} />
            <Route path="movements/:id" element={<UserRoute><MovementDetails /></UserRoute>}/>


            {/* Effects Model */}
            <Route path="effects" element={<UserRoute><EffectList effectUrl="effects" effectTitle="Effects" /></UserRoute>} />
            <Route path="concurrent-groupings" element={<UserRoute><EffectList effectUrl="concurrent-grouping" effectTitle="Concurrent Grouping" /></UserRoute>} />
            <Route path="blends" element={<UserRoute><EffectList effectUrl="blend" effectTitle="Blends" /></UserRoute>} />
            <Route path="timbral-augmentations" element={<UserRoute><EffectList effectUrl="timbral-augmentation" effectTitle="Timbral Augmentations" /></UserRoute>} />
            <Route path="timbral-emergences" element={<UserRoute><EffectList effectUrl="timbral-emergence" effectTitle="Timbral Emergences" /></UserRoute>} />
            <Route path="non-blends" element={<UserRoute><EffectList effectUrl="non-blend" effectTitle="Non Blends" /></UserRoute>} />
            <Route path="timbral-heterogeneities" element={<UserRoute><EffectList effectUrl="timbral-heterogeneity" effectTitle="Timbral Heterogeneities" /></UserRoute>} />
            <Route path="sequential-groupings" element={<UserRoute><EffectList effectUrl="sequential-grouping" effectTitle="Sequential Grouping" /></UserRoute>} />
            <Route path="segregations" element={<UserRoute><EffectList effectUrl="segregation" effectTitle="Segregations" /></UserRoute>} />
            <Route path="stratifications" element={<UserRoute><EffectList effectUrl="stratification" effectTitle="Stratifications" /></UserRoute>} />
            <Route path="streams" element={<UserRoute><EffectList effectUrl="streams" effectTitle="Streams" /></UserRoute>} />
            <Route path="integrations" element={<UserRoute><EffectList effectUrl="integration" effectTitle="Integrations" /></UserRoute>} />
            <Route path="surface-textures" element={<UserRoute><EffectList effectUrl="surface-texture" effectTitle="Surface Textures" /></UserRoute>} />
            <Route path="stream" element={<UserRoute><EffectList effectUrl="stream" effectTitle="Stream" /></UserRoute>} />
            <Route path="segmental-groupings" element={<UserRoute><EffectList effectUrl="segmental-grouping" effectTitle="Segmental Grouping" /></UserRoute>} />
            <Route path="timbral-contrasts" element={<UserRoute><EffectList effectUrl="timbral-contrast" effectTitle="Timbral Contrast" /></UserRoute>} />
            <Route path="antiphonal-contrasts" element={<UserRoute><EffectList effectUrl="antiphonal-contrast" effectTitle="Antiphonal Contrast" /></UserRoute>} />
            <Route path="timbral-echoes" element={<UserRoute><EffectList effectUrl="timbral-echo" effectTitle="Timbral Echoes" /></UserRoute>} />
            <Route path="timbral-shifts" element={<UserRoute><EffectList effectUrl="timbral-shift" effectTitle="Timbral Shifts" /></UserRoute>} />
            <Route path="timbral-juxtapositions" element={<UserRoute><EffectList effectUrl="timbral-juxtaposition" effectTitle="Timbral Juxtapositions" /></UserRoute>} />
            <Route path="sectional-contrasts" element={<UserRoute><EffectList effectUrl="sectional-contrast" effectTitle="Sectional Contrasts" /></UserRoute>} />
            <Route path="timbral-progressions" element={<UserRoute><EffectList effectUrl="timbral-progression" effectTitle="Timbral Progression" /></UserRoute>} />
            <Route path="orchestral-gestures" element={<UserRoute><EffectList effectUrl="orchestral-gesture" effectTitle="Orchestral Gestures" /></UserRoute>} />

            <Route path="effect/:id" element={<UserRoute><EffectDetail /></UserRoute>}/>
           </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}
