import React from "react";
import "./Spinner.css"
import ReactLoading from "react-loading";

// Spinner from https://www.npmjs.com/package/react-loading

export default function Spinner(props) {

  return (
    <div>
      <div className="spinner">
        <ReactLoading
            type={"bars"}
            color={"#DCC4AE"}
            height={150}
            width={150}
        />
      </div>
    </div>   
  );
}