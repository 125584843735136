import axios from "axios";
import env from '../../../../env/env';
import { Utils } from "react-awesome-query-builder/lib";


/**
 * This function is intended to be used as a React effect to fetch all the data for instruments
 * in order to display them in the querybuilder.
 */
export default function fetchInstrumentation(setConfig) {
    
    let instruments;
    let listValues = [];

    axios.post(env.ELASTIC_API_URL + '/orchard_index/_search/', {
        "size": 0,
        "aggs": {
            "instruments": {
                "terms": {
                    "field": "instruments_exact",
                    "size": 200,
                }
            }
        }})
        .then((res) => {

            instruments = res.data.aggregations.instruments.buckets;

            // sort alphabetically
            instruments.sort((a, b) => a.key.localeCompare(b.key));

            for (let instrument of instruments) {
                listValues.push({value: instrument.key, title: instrument.key, path: []});
            }

            const simulatedAsyncFetch = Utils.simulateAsyncFetch(listValues, 100);

            setConfig(prevConfig => {
                return {
                    ...prevConfig,
                    fields: {
                        ...prevConfig.fields,
                            instruments_exact: {
                                ...prevConfig.fields.instruments_exact,
                                fieldSettings: {
                                    ...prevConfig.fields.instruments_exact.fieldSettings,
                                    asyncFetch: simulatedAsyncFetch,
                                    useAsyncSearch: true,
                                    useLoadMore: true,
                                    forceAsyncSearch: false,
                                    allowCustomValues: false
                                }
                            }
                    }
                }
            })
        })
        .catch((err) => console.log(err));
}