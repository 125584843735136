import React from "react"
import { Link } from "react-router-dom";
import "./NeedHelp.css"
import { Box, Typography } from '@mui/material';

export default function NeedHelp() {
    return (
        <Box 
            sx={{ 
                maxWidth: '300px',
                minWidth: 120, 
                boxShadow: 1, 
                borderRadius: '16px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '16px',
                marginTop: '16px',
                marginLeft: '4px',
                marginRight: '4px',
                marginBottom: '4px',
            }}
        >
            <Typography variant="h6">Need help?</Typography>
            <div className="tutorial--links">
                <Link to="/querybuilder/tutorial">Take me to the tutorial</Link>
                {/* <br />
                <a href="#1">Show me an example</a> */}
            </div>
        </Box>
    )
}