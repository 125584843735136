import React, { useState, useEffect } from "react";
import "./EffectDetail.css";
import axios from "axios";
import env from '../../env/env';
import Spinner from '../core/spinner/Spinner';
import { PDFObject } from 'react-pdfobject';
import ReactAudioPlayer from 'react-audio-player';
import { Paper, Stack, Modal, Box, Typography } from "@mui/material";


export default function EffectDetail() {
    const [effectData, seteffectData] = useState(null);
    const effectId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    const [isOpen, setIsOpen] = useState(false);
    const [clickedEvolution, setClickedEvolution] = useState();
    const [done, setDone] = useState(undefined);

    const handleModalClose = () => setIsOpen(false);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px',
        overflow: 'hidden',
        overflowY: "scroll",
        maxHeight: '100%'
    };

    // Call the API to get the data on inital loading
    useEffect(() => {
        axios.get(env.BACKEND_API_URL + '/api/effects/'+ effectId.toString() + '/')
        .then((res) => {
            if(res.status === 200) {
                seteffectData(res.data);
                setDone(true);
            } 
            else {
                throw Error;
            }
        })
        .catch(() => {
            window.location.href = "/";  
        });

    // eslint-disable-next-line
    }, []);

    function handleEvolutionClick(evolution_index) {
        setIsOpen(true);
        setClickedEvolution(effectData.evolutions[evolution_index]);
    };

    return (
        <div className="effectDetail--container">
            <header className="effectDetail--header">
                <h1>Effect's Details</h1>
            </header>           
                {!done ? (
                    <Spinner />
                ) : (
                    <div className="effectDetail--content">
                        <h1>{effectData.effectType}</h1>
                        <div className="effectDetail--grid">
                            <div className={`effectDetail--pdf-container`}>
                                {effectData.score_location ? (                               
                                    <div>
                                        {/* PDF Viewer */}
                                        <PDFObject 
                                            url={env.BACKEND_API_URL + effectData.score_location} 
                                            width='50vw'
                                            height="100vh"
                                            page={effectData.pdf_page_for_score}
                                        />
                                    </div>                               
                                ): (
                                    <div>Troubles occured while loading the pdf...</div>                                
                                )}
                            </div>
                            <div className="effectDetail--right-section">
                                <Paper elevation={3} style={{ padding: '20px', backgroundColor: 'white' }}>
                                    <h2>{effectData.perceptionType} - {effectData.groupingType} grouping</h2>
                                    <p>Work : {effectData.name}</p>
                                    <p>Duration : {effectData.durationType}</p>
                                    <p>Evolution : {effectData.evolutionType}</p>
                                    <p>Measures : {effectData.annotation.first_bar} - {effectData.annotation.last_bar}</p>
                                    <p>Time : {effectData.annotation.time_start} - {effectData.annotation.time_end}</p>
                                    <p>Strength : {effectData.annotation.strength}</p>
                                    <p>Score Pages : {effectData.annotation.first_page} - {effectData.annotation.last_page}</p>
                                    {effectData.annotation.pdf_page_start && <p>PDF pages : {effectData.annotation.pdf_page_start} - {effectData.annotation.pdf_page_end}</p>}
                                    
                                    {effectData.notes && <p>Notes : {effectData.notes}</p>}
                                    <p>Scored Instruments: {effectData.scored_instruments.join(', ')}</p>
                                    <Stack>
                                        {effectData.evolutions.map((evolution, i) => {
                                            return (
                                                <button 
                                                    key={i}
                                                    style={{maxWidth:"10em"}}
                                                    className="effectDetail--table-button"
                                                    onClick={e => handleEvolutionClick(i)}
                                                ><u>Evolution #{i+1}</u></button>
                                            );
                                        })}
                                    </Stack>
                                    {!isOpen && 
                                        <div style={{paddingTop:'20px'}}>
                                            <ReactAudioPlayer src={env.BACKEND_API_URL + effectData.sound_file_location} controls />
                                        </div>
                                    }
                                </Paper>
                                <br />
                                
                            </div>
                        </div>
                        {clickedEvolution && <Modal 
                            open={isOpen}
                            onClose={handleModalClose}
                        >
                            <Box sx={modalStyle}>
                                <Typography
                                    variant="h5"
                                    align="center"
                                >
                                    Evolution {clickedEvolution.first_bar > -1 ? (clickedEvolution.first_bar+" - "+clickedEvolution.last_bar): ("")}
                                </Typography>
                                <div className="evolution--modal-modalContent">
                                    <table className="evolution--modal--table-primary">
                                        <thead> 
                                            <tr>
                                                <th className="evolution--th">Group</th>
                                                <th className="evolution--th">Title</th>
                                                <th className="evolution--th">Instruments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {clickedEvolution.instrumentGroups?.map((group, j) => {
                                                    return (
                                                        <tr key={j}>
                                                            <td>
                                                                <Typography align="center">{group.type}</Typography>
                                                            </td>
                                                            <td>
                                                                <Typography align="center">{group.title ? group.title : "-"}</Typography>
                                                            </td>
                                                            <td>
                                                                <Typography variant="body2" align="center">
                                                                {
                                                                    group.instruments.map((instrument) =>
                                                                        instrument.name.charAt(0).toUpperCase() + instrument.name.slice(1)
                                                                    ).join(', ')
                                                                } 
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                    
                                </div>
                            </Box>
                        </Modal>}
                    </div>
                )}       
        </div>
    )
}