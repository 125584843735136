import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Spinner from '../../core/spinner/Spinner';
import env from '../../../env/env';
// MUI
import { Grid, Typography } from '@mui/material';
// Image file
import logo from '../../../images/treeimg.png';
// CSS file
import "./Registration.css";


export default function Activation() {
    const {username_to_activate} = useParams();
    const [message, setMessage] = useState("");
    const [done, setDone] = useState(false);

    useEffect(() => {
        if (username_to_activate) {
            axios.post(env.BACKEND_API_URL + '/api/accounts/activate/', {username:username_to_activate})
                .then((res) => {
                    if (res.status !== 200) {
                        throw new Error(res.response.data.detail);
                    }

                    setMessage(res.data.detail)
                })
                .catch((error) => {
                    setMessage(String(error))
                });
                
                setDone(true);
        } else {
            setMessage("The link is not valid.")
        }
             
    // eslint-disable-next-line
    }, [])

    return (
        <div>
            <div className="hp--container">
                <div className="hp--title">
                    <div className="hp--title-text">
                        <h2><span className="title--1">O</span><span className="title--2">RCH</span><span className="title--3">ESTRATION</span></h2>
                        <h2><span className="title--1">A</span><span className="title--3">NALYSIS &</span></h2>
                        <h2><span className="title--1">R</span><span className="title--3">ESEARCH</span></h2>
                        <h2><span className="title--1">D</span><span className="title--3">ATABASE</span></h2>
                    </div>
                    <img src={logo} alt="orchard logo" />
                </div>
                <br />
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    >
                        {!done ? (
                            <Spinner />
                        ) : (
                            <Grid item xs={3}>
                                    <Typography align="center" variant="h5">
                                        <b>{message}</b>
                                    </Typography>
                            </Grid>
                        )}
                </Grid>
            </div>
        </div>
    )
}