import React, { useState } from "react";
import "./SortHierarchy.css"
import { Box, Button, FormControlLabel, Grid, 
    MenuItem, Radio, RadioGroup, Select } from '@mui/material';

export default function SortHierarchy(props) {
    const [sortingField1, setSortingField1] = useState("");
    const [sortingOrder1, setSortingOrder1] = useState("asc");
    const [sortingField2, setSortingField2] = useState("");
    const [sortingOrder2, setSortingOrder2] = useState("asc");
    const [sortingField3, setSortingField3] = useState("");
    const [sortingOrder3, setSortingOrder3] = useState("asc");
    const [sortingField4, setSortingField4] = useState("");
    const [sortingOrder4, setSortingOrder4] = useState("asc");
    const [sortingField5, setSortingField5] = useState("");
    const [sortingOrder5, setSortingOrder5] = useState("asc");

    const handleSortingField1 = (event) => { setSortingField1(event.target.value); };
    const handleSortingOrder1 = (event) => { setSortingOrder1(event.target.value); };
    const handleSortingField2 = (event) => { setSortingField2(event.target.value); };
    const handleSortingOrder2 = (event) => { setSortingOrder2(event.target.value); };
    const handleSortingField3 = (event) => { setSortingField3(event.target.value); };
    const handleSortingOrder3 = (event) => { setSortingOrder3(event.target.value); };
    const handleSortingField4 = (event) => { setSortingField4(event.target.value); };
    const handleSortingOrder4 = (event) => { setSortingOrder4(event.target.value); };
    const handleSortingField5 = (event) => { setSortingField5(event.target.value); };
    const handleSortingOrder5 = (event) => { setSortingOrder5(event.target.value); };

    // Function that creates the JSON object in a format ElasticSearch likes
    const createSortingForElasticSearch = () => {
        const sortingArray = []
        let sortingObject = {}

        if(sortingField1) {
            sortingObject = {[sortingField1] : {"order" : sortingOrder1}}
            sortingArray.push(sortingObject)
        }
        if(sortingField2) {
            sortingObject = {[sortingField2] : {"order" : sortingOrder2}}
            sortingArray.push(sortingObject)
        }
        if(sortingField3) {
            sortingObject = {[sortingField3] : {"order" : sortingOrder3}}
            sortingArray.push(sortingObject)
        }
        if(sortingField4) {
            sortingObject = {[sortingField3] : {"order" : sortingOrder3}}
            sortingArray.push(sortingObject)
        }
        if(sortingField5) {
            sortingObject = {[sortingField3] : {"order" : sortingOrder3}}
            sortingArray.push(sortingObject)
        }

        props.handleSortingCallback(sortingArray)
    }

    const clearSorting = () => { 
        setSortingField1("");
        setSortingField2("");
        setSortingField3("");
        setSortingField4("");
        setSortingField5("");
        props.handleSortingCallback([]);
    };

    return (
        <div>
            <Box 
                sx={{ 
                    maxWidth: '300px',
                    minWidth: '200px', 
                    boxShadow: 1, 
                    borderRadius: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '16px',
                    margin: '4px'
                }}
            >   
                <legend>Sort hierarchy</legend>           
                <Grid
                    container
                    sx={{ 
                        minWidth: 120,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                    }}
                >
                    <Grid 
                        item
                        sx={{ 
                            display: 'inline-flex'
                        }}
                    >
                        <Select
                            value={sortingField1}
                            onChange={handleSortingField1}
                            sx={{ m: 1, minWidth: 120 }}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value={"composer_exact"}>Composer</MenuItem>
                            <MenuItem value={"piece_exact"}>Piece</MenuItem>
                            <MenuItem value={"movement_exact"}>Movement</MenuItem>
                            <MenuItem value={"first_bar_exact"}>Measure</MenuItem>
                            <MenuItem value={"strength_exact"}>Strength</MenuItem>
                            <MenuItem value={"composition_year"}>Year</MenuItem>
                        </Select>
                    </Grid>
                    { sortingField1 && <Grid item xs={4}>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={sortingOrder1}
                            onChange={handleSortingOrder1}
                        >
                            <FormControlLabel value="asc" control={<Radio />} label="ASC" />
                            <FormControlLabel value="desc" control={<Radio />} label="DESC" />
                        </RadioGroup>
                    </Grid>}
                </Grid>
                <Grid
                    container 
                    sx={{ 
                        minWidth: 120,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                    }}
                >
                    <Grid item>
                        <Select
                            value={sortingField2}
                            onChange={handleSortingField2}
                            sx={{ m: 1, minWidth: 120 }}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value={"composer_exact"}>Composer</MenuItem>
                            <MenuItem value={"piece_exact"}>Piece</MenuItem>
                            <MenuItem value={"movement_exact"}>Movement</MenuItem>
                            <MenuItem value={"first_bar_exact"}>Measure</MenuItem>
                            <MenuItem value={"strength_exact"}>Strength</MenuItem>
                            <MenuItem value={"composition_year"}>Year</MenuItem>
                        </Select>
                    </Grid>
                    { sortingField2 && <Grid item xs={4}>
                        <RadioGroup
                            value={sortingOrder2}
                            onChange={handleSortingOrder2}
                        >
                            <FormControlLabel value="asc" control={<Radio />} label="ASC" />
                            <FormControlLabel value="desc" control={<Radio />} label="DESC" />
                        </RadioGroup>
                    </Grid>}
                </Grid>
                <Grid
                    container 
                    sx={{ 
                        minWidth: 120,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                    }}
                >
                    <Grid item>
                        <Select
                            value={sortingField3}
                            onChange={handleSortingField3}
                            sx={{ m: 1, minWidth: 120 }}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value={"composer_exact"}>Composer</MenuItem>
                            <MenuItem value={"piece_exact"}>Piece</MenuItem>
                            <MenuItem value={"movement_exact"}>Movement</MenuItem>
                            <MenuItem value={"first_bar_exact"}>Measure</MenuItem>
                            <MenuItem value={"strength_exact"}>Strength</MenuItem>
                            <MenuItem value={"composition_year"}>Year</MenuItem>
                        </Select>
                    </Grid>
                    { sortingField3 && <Grid item xs={4}>
                        <RadioGroup
                            value={sortingOrder3}
                            onChange={handleSortingOrder3}
                        >
                            <FormControlLabel value="asc" control={<Radio />} label="ASC" />
                            <FormControlLabel value="desc" control={<Radio />} label="DESC" />
                        </RadioGroup>
                    </Grid>}
                </Grid>
                <Grid
                    container 
                    sx={{ 
                        minWidth: 120,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                    }}
                >
                    <Grid item>
                        <Select
                            value={sortingField4}
                            onChange={handleSortingField4}
                            sx={{ m: 1, minWidth: 120 }}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value={"composer_exact"}>Composer</MenuItem>
                            <MenuItem value={"piece_exact"}>Piece</MenuItem>
                            <MenuItem value={"movement_exact"}>Movement</MenuItem>
                            <MenuItem value={"first_bar_exact"}>Measure</MenuItem>
                            <MenuItem value={"strength_exact"}>Strength</MenuItem>
                            <MenuItem value={"composition_year"}>Year</MenuItem>
                        </Select>
                    </Grid>
                    { sortingField4 && <Grid item xs={4}>
                        <RadioGroup
                            value={sortingOrder4}
                            onChange={handleSortingOrder4}
                        >
                            <FormControlLabel value="asc" control={<Radio />} label="ASC" />
                            <FormControlLabel value="desc" control={<Radio />} label="DESC" />
                        </RadioGroup>
                    </Grid>}
                </Grid>
                <Grid
                    container 
                    sx={{ 
                        minWidth: 120,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                    }}
                >
                    <Grid item>
                        <Select
                            value={sortingField5}
                            onChange={handleSortingField5}
                            sx={{ m: 1, minWidth: 120 }}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value={"composer_exact"}>Composer</MenuItem>
                            <MenuItem value={"piece_exact"}>Piece</MenuItem>
                            <MenuItem value={"movement_exact"}>Movement</MenuItem>
                            <MenuItem value={"first_bar_exact"}>Measure</MenuItem>
                            <MenuItem value={"strength_exact"}>Strength</MenuItem>
                            <MenuItem value={"composition_year"}>Year</MenuItem>
                        </Select>
                    </Grid>
                    { sortingField5 && <Grid item xs={4}>
                        <RadioGroup
                            value={sortingOrder5}
                            onChange={handleSortingOrder5}
                        >
                            <FormControlLabel value="asc" control={<Radio />} label="ASC" />
                            <FormControlLabel value="desc" control={<Radio />} label="DESC" />
                        </RadioGroup>
                    </Grid>}
                </Grid>
                {(sortingField1 || sortingField2 || sortingField3 || sortingField4 || sortingField5) && 
                    <Grid
                        sx={{ 
                            maxWidth: '200px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left',
                            pt: '16px',
                            pl: '1px',
                            pb: 'px'
                        }}
                    >
                        <Button 
                            variant="contained"
                            color="success"
                            onClick={createSortingForElasticSearch}
                            sx={{ 
                                minWidth: 120,
                                pr: '8px',
                                pl: '16px',
                                mr: '4px'
                            }}
                        ><b>Sort</b></Button>
                        <Button 
                            variant="contained"
                            color="error"
                            onClick={clearSorting}
                            sx={{ 
                                ml: '4px'
                            }}
                        ><b>Clear</b></Button>
                    </Grid>
                }
            </Box>
        </div>
    )
}