import React, { useState } from "react";
import { Box, Grid, Paper } from '@mui/material';
// Styles
import "./Querybuilder.css"
import 'react-awesome-query-builder/lib/css/styles.css';
// Components
import SortHierarchy from "./sort-hierarchy/SortHierarchy";
import NeedHelp from "./need-help/NeedHelp";
import Main from "./main/Main";
import QuerySaver from "./query-saver/QuerySaver";


export default function QueryBuilder() {
    const [sortingQuery, setSortingQuery] = useState([]);
    const [searchQuery, setSearchQuery] = useState(undefined);

    // Handle SortHierarchy child component updating sorting
    const handleSortingCallback = (childData) => {
        setSortingQuery(childData);
    }

    // Handle SaveQuery child component
    const updateSearchQueryFromMain = (childData) => {
        setSearchQuery(childData);
    }

    return (
        <div className="qb--content">
            <header className="qb--header">
                <h1 className="qb--header-h1">QueryBuilder</h1>
            </header>
            <div className="qb--main">
                <Paper elevation={3} style={{ padding: '20px', backgroundColor: 'white' }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <div>
                                    <NeedHelp />
                                </div>
                                <br />
                                <div className="qb--sh">
                                    <SortHierarchy 
                                        handleSortingCallback={handleSortingCallback} 
                                    />
                                </div>                      
                                <QuerySaver queryType="QS" parameters={searchQuery} />
                            </Grid>
                            <Grid item xs={9}>
                                <div className="qb--group">
                                    <Main 
                                        sortingQuery={sortingQuery} 
                                        updateSearchQueryForParentComponent={updateSearchQueryFromMain}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </div>
        </div>
    )
}