import React, { useState } from 'react';
import axios from "axios";
import env from '../../../env/env';
import { Button, Grid, TextField, Alert, Collapse, 
         Typography, Paper, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../../images/treeimg.png';
import "./PasswordReset.css";

export default function PasswordReset() {
    const [email, setEmail] = useState("");

    const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState(undefined);
    const [alertFailureOpen, setAlertFailureOpen] = useState(false);
    const [failureMessage, setFailureMessage] = useState(undefined);

    const handleSubmit = (event) => {
        // API call to reset the password
        axios.post(env.BACKEND_API_URL + '/api/accounts/password_reset/', {email: email})
            .then((res) => {
                if(res.status !== 200) {
                    if (res.response.data.detail) {
                        throw new Error(res.response.data.detail);
                    } else if (res.response.data.email) {
                        throw new Error(res.response.data.email);
                    } else {
                        throw new Error(res.response.data);
                    }                   
                }
                setSuccessMessage(String(res.data.detail));         
                setAlertSuccessOpen(true);
                setAlertFailureOpen(false);
                setFailureMessage("");

            }).catch(error => {
                setFailureMessage(String(error));                
                setAlertFailureOpen(true);
                setAlertSuccessOpen(false);
                setSuccessMessage("");
            });
    }

    return(
        <div>
            <div className="hp--container">
            <Paper elevation={3} style={{ padding: '50px', backgroundColor: 'white' }}>

                <div className="pr--title">
                    <div className="hp--title-text">
                        <h2><span className="title--1">O</span><span className="title--2">RCH</span><span className="title--3">ESTRATION</span></h2>
                        <h2><span className="title--1">A</span><span className="title--3">NALYSIS &</span></h2>
                        <h2><span className="title--1">R</span><span className="title--3">ESEARCH</span></h2>
                        <h2><span className="title--1">D</span><span className="title--3">ATABASE</span></h2>
                    </div>
                    <img src={logo} alt="orchard logo" />
                </div>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    >
                        <Grid item xs={3}>
                                <Typography align="center">
                                    <b>Forgotten your password?</b>
                                </Typography>
                                <Typography align="center">
                                    <b>Enter your email address below, and we will email</b>
                                </Typography>
                                <Typography align="center">
                                    <b>instructions for setting a new password if an account is </b>
                                </Typography>
                                <Typography align="center">
                                    <b>linked to this email</b>
                                </Typography>
                                <Collapse in={alertSuccessOpen}>
                                    <Alert
                                        variant="standard" severity="success"
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setAlertSuccessOpen(false);
                                                }}
                                            >
                                            <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        sx={{ mb: 2 }}
                                        >
                                        {successMessage}
                                    </Alert>
                                </Collapse>
                                <Collapse in={alertFailureOpen}>
                                    <Alert
                                        variant="standard" severity="error"
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setAlertFailureOpen(false);
                                                }}
                                            >
                                            <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        sx={{ mb: 2 }}
                                        >
                                        {failureMessage}
                                    </Alert>
                                </Collapse>
                                <TextField
                                    align="center"
                                    required
                                    fullWidth
                                    margin="normal"
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    sx={{width: "80%"}}
                                />
                                <Button 
                                    align="center"
                                    variant="contained" 
                                    onClick={handleSubmit} 
                                    disabled={!email}
                                    sx={{ mt: '1em'}}
                                ><b>Reset Password</b></Button>
                        </Grid>
                </Grid>
            </Paper>
            </div>
        </div>
    );
}
