import React, { useEffect, useState } from "react";
import "./EffectList.css";
import axios from "axios";
import env from '../../env/env';
import Spinner from "../core/spinner/Spinner";
import SmallLoading from "../core/spinner/small-loading/small-loading";
import { useNavigate } from "react-router-dom";
import { Typography, Paper, Button, ButtonGroup, Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

export default function EffectList(props) {

    const effectUrl = props.effectUrl;
    const [effectTitle, setEffectTitle] = useState("");
    const [effects, setEffects] = useState([]);
    const [done, setDone] = useState(false);
    
    // pagination
    const [numResults, setNumResults] = useState(undefined);
    const [page, setPage] = useState(1);
    const [numPages, setNumPages] = useState(undefined);
    const [changePageDone, setChangePageDone] = useState(true);

    const navigate = useNavigate();

    // On load
    useEffect(() => {
        setEffectTitle(props.effectTitle);
        setDone(false);
    }, [props.effectTitle]);

    useEffect(() => {
        // API call to get the effects
        axios.get(env.BACKEND_API_URL + '/api/' + effectUrl + '/?page=' + page).then((res) => {
            setEffects(res.data.results);
            setNumResults(res.data.count);
            setNumPages(Math.ceil(res.data.count / 50));
            setDone(true);
            setChangePageDone(true);
        })
    }, [effectUrl, props.effectTitle, page]);
    
    // Page navigation functions
    function handleNextPageClick() {
        setChangePageDone(false);
        setPage(page + 1);   
    }
    function handlePrevPageClick() {
        setChangePageDone(false);
        setPage(page - 1);
    }

    // Effect navigation function
    function handleEffectClick(django_id){
        navigate(`/effect/${django_id}`);
    }

    // Display of measures
    function MetronomeDisplay(first_bar, last_bar){

        if (first_bar === last_bar){
            return (<td>mm. {first_bar}</td>)
        }
        else {
            return (<td>mm. {first_bar}-{last_bar}</td>)
        }
    }

    return (
        <div className="effects--content">
            <header className="effects--header">
                    <h1>{effectTitle}</h1>
            </header>
            {!done ? (
                <Spinner />
            ):(                
                <div className="effects--main">
                    <Paper elevation={3} style={{ padding: '20px', backgroundColor: 'white' }}>
                        <div>
                            <ButtonGroup variant="outlined" size="small" aria-label="large button group">
                                <Button onClick={handlePrevPageClick} disabled={page === 1}> Prev</Button>
                                <Button onClick={handleNextPageClick} disabled={page === numPages}> Next</Button>
                                     
                                {numPages !== 0 &&  <p style={{ marginLeft: "20px", marginTop: "8px"}}>Page {page} of {numPages} - {numResults} results</p>}
                                {!changePageDone && <SmallLoading />}
                            </ButtonGroup> 
                        </div>
                        <table className="effects--table-primary">
                            <thead>
                                <tr>
                                    <th className="effects--th" width="30%">Effects 
                                        <Tooltip 
                                            title={
                                                <React.Fragment>
                                                    <Typography variant="body2">Composer - Piece - Movement - Orchestra</Typography>
                                                </React.Fragment>
                                            }
                                        >
                                            <InfoIcon sx={{paddingTop:'5px', marginLeft:'5px'}}></InfoIcon>
                                        </Tooltip>    
                                    </th>
                                    <th className="effects--th" width="10%">Measures</th>
                                    <th className="effects--th" width="30%">Instruments</th>
                                    <th className="effects--th" width="30%">Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    effects.map((effect, i) => {
                                        return (
                                            <tr key={i}>
                                                <td><Typography><a onClick={e => handleEffectClick(effect.id)}><b>{effect.name}</b></a></Typography></td>
                                                {MetronomeDisplay(effect.annotation.first_bar, effect.annotation.last_bar)}
                                                <td>{effect.scored_instruments.join(', ')}</td>
                                                <td>{effect.notes}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <div>
                            <ButtonGroup 
                                variant="outlined" 
                                size="small" 
                                aria-label="large button group"
                                style={{marginTop: '1em'}}
                            >
                                <Button onClick={handlePrevPageClick} disabled={page === 1}> Prev</Button>
                                <Button onClick={handleNextPageClick} disabled={page === numPages}> Next</Button>
                                     
                                {numPages !== 0 &&  <p style={{ marginLeft: "20px", marginTop: "8px"}}>Page {page} of {numPages} - {numResults} results</p>}
                                {!changePageDone && <SmallLoading />}
                            </ButtonGroup> 
                        </div>
                    </Paper>
                </div>
            )}            
        </div>
    )

}