import axios from "axios";

axios.interceptors.request.use(req => {
    const accessToken = localStorage.getItem("access_token")
    if (accessToken) {
        req.headers["Authorization"] = `Bearer ${accessToken}`
    }
    return req
});

axios.interceptors.response.use(resp => resp, async error => {
    if (error.response.status === 401) {  
        //check if it's a login error
        if (error.response.data.detail === "No active account found with the given credentials") {
            return error
        }

        axios.post("http://localhost:8000/api/accounts/refresh_token/",{}, { withCredentials: true })
            .then((res) => {
                if (res.status === 200) {
                    localStorage.setItem("role", res.data.role)
                    localStorage.setItem("access_token", res.data.access)
                    localStorage.setItem("username", res.data.username)
                    window.location.reload() 
                } else {
                    throw Error()
                }
            })    
            .catch(() => {
                localStorage.clear()
                localStorage.setItem('username', "")
                localStorage.setItem('role', "")
            })
        
        return error

    } else {
        return error
    }
});