import React, { useState } from "react";
import axios from "axios";
import "./Registration.css";
import { Link, useNavigate } from "react-router-dom";
import env from '../../../env/env';
// MUI
import { Alert, Collapse, IconButton, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function Registration() {
    const [alertFailureOpen, setAlertFailureOpen] = useState(false);
    const [failureMessage, setFailureMessage] = useState(undefined);

    // Values of the fields on the form
    const [fields, setFields] = React.useState(
        {
            username: "",
            firstname: "",
            lastname: "",
            email: "",
            password1: "",
            password2: "",
            institution: "",
            position: "",
            interest: "",
            reference: ""
        }
    );

    // Error messages which will be displayed below the form fields
    const [errMsgs, setErrMsgs] = React.useState(
        {
            username: "",
            firstname: "",
            lastname: "",
            email: "",
            password1: "",
            password2: "",
            institution: "",
            position: "",
            interest: "",
            reference: ""
        }
    )

    const navigate = useNavigate();

    React.useEffect(() => {
        const loggedInUser = localStorage.getItem("username")
        if(loggedInUser){
            navigate("/")
        }
    })

    /**
     * Sets the values of the form fields
     * 
     * @param {*} event    
     *      onChange event in one of the form fields
     */
    function handleChange(event) {
        setFields(prevFields => {
            return {
                ...prevFields,
                [event.target.name]: event.target.value
            }
        })
    }


    /**
     * Checks the form once it has been submitted
     * 
     * @returns 1 if the form is invalid, redirects otherwise
     */
    function handleSubmit() {
        if (checkFieldComp()) {
            if (!validatePassword()) {
                confirmPassword()
                return 1
            }

            if (!confirmPassword()) {
                setErrMsgs(prevMsgs => {
                    return {
                        ...prevMsgs,
                        password1: "Your passwords don't match"
                    }
                })
                return 1
            }

            // API call to register the new user
            axios.post(env.BACKEND_API_URL + '/api/accounts/register/', {
                username: fields.username,
                first_name: fields.firstname,
                last_name: fields.lastname,
                email: fields.email,
                password: fields.password1,
                institution: fields.institution,
                position: fields.position,
                interest: fields.interest,
                reference: fields.reference
            }).then((res) => {
                if(res.status !== 200) {
                    if (res.response.data.detail ) {
                        const detailMessage = res.response.data.detail.split("DETAIL:  Key ")
                        throw new Error(detailMessage[1]);
                    } else if (res.response.data.email) {
                        throw new Error(res.response.data.email);
                    } else {
                        throw new Error(res.response.data);
                    }                   
                }

                navigate("/registration_done");

            }).catch(error => {
                setFailureMessage(String(error));
                setAlertFailureOpen(true);
            });
        }
    }

    /**
     * Checks if all fields have been filled in, and sets and error message
     *  if a field is left empty
     * 
     * @returns true if all fields have been filled, false otherwise
     */
    function checkFieldComp() {
        let fieldsComplete = true
        for (const key in fields) {
            if (!fields[key])
                fieldsComplete = false
            setErrMsgs(prevMsgs => {
                return {
                    ...prevMsgs,
                    [key]: fields[key] ? "" : "*You must fill out this field"
                }
            })
        }
        return fieldsComplete
    }

    /**
     * Checks if password is valid
     *  - contains at least one number
     *  - contains at least one letter
     *  - contains at least one special character
     *  - is at least 6 characters long
     *  - is at most 20 characters long
     *  - is not the username, firstname, lastname, or email
     * 
     * Sets appropriate error message when the password isn't valid
     * 
     * @returns true if password is valid, false otherwise
     */
    function validatePassword() {
        let hasNumber = /\d/
        let hasLetter = /[A-Za-z]/
        let hasSpecial = /\W/
        let password = fields.password1

        if (!hasNumber.test(password)) {
            setErrMsgs(prevMsgs => {
                return {
                    ...prevMsgs,
                    password1: "Your password must contain a number",
                    password2: "Your password must contain a number"
                }
            })
            return false
        } else if (!hasLetter.test(password)) {
            setErrMsgs(prevMsgs => {
                return {
                    ...prevMsgs,
                    password1: "Your password must contain a letter",
                    password2: "Your password must contain a letter"
                }
            })
            return false
        } else if (!hasSpecial.test(password)) {
            setErrMsgs(prevMsgs => {
                return {
                    ...prevMsgs,
                    password1: "Your password must contain a special character",
                    password2: "Your password must contain a special character"

                }
            })
            return false
        } else if (password.length < 8) {
            setErrMsgs(prevMsgs => {
                return {
                    ...prevMsgs,
                    password1: "Your password must be at least 8 characters long",
                    password2: "Your password must be at least 8 characters long"
                }
            })
            return false
        } else if (password.length > 30) {
            setErrMsgs(prevMsgs => {
                return {
                    ...prevMsgs,
                    password1: "Your password cannot contain more than 30 characters",
                    password2: "Your password cannot contain more than 30 characters"
                }
            })
            return false
        } else if (password === fields.username) {
            setErrMsgs(prevMsgs => {
                return {
                    ...prevMsgs,
                    password1: "Your password cannot be the same as your username",
                    password2: "Your password cannot be the same as your username"
                }
            })
            return false
        } else if (password === fields.firstname) {
            setErrMsgs(prevMsgs => {
                return {
                    ...prevMsgs,
                    password1: "Your password cannot be the same as your name",
                    password2: "Your password cannot be the same as your name"
                }
            })
            return false
        } else if (password === fields.lastname) {
            setErrMsgs(prevMsgs => {
                return {
                    ...prevMsgs,
                    password1: "Your password cannot be the same as your name",
                    password2: "Your password cannot be the same as your name"
                }
            })
            return false
        } else if (password === fields.email) {
            setErrMsgs(prevMsgs => {
                return {
                    ...prevMsgs,
                    password1: "Your password cannot be the same as your email",
                    password2: "Your password cannot be the same as your email"
                }
            })
            return false
        }
        return true
    }

    /**
     * Checks if password2 is the same as password1
     * Sets the appropriate error message when passwords don't match
     * 
     * @returns true if they match, false otherwise
     */
    function confirmPassword() {
        if (fields.password1 !== fields.password2) {
            setErrMsgs(prevMsgs => {
                return {
                    ...prevMsgs,
                    password2: "Your passwords don't match"
                }
            })
            return false
        }

        return true
    }

    return (
        <div className="reg--container">
            <div className="reg--form">
            <Paper 
                elevation={3} 
                style={{ padding: '20px', backgroundColor: 'white', minWidth: '30em' }}
            >
            
                <h1 className="reg--title 1">Create an account:</h1>
                <form>
                    <label htmlFor="username">Username*</label>
                    <br />
                    <input
                        className="reg--input"
                        type="text"
                        name="username"
                        id="username"
                        value={fields.username}
                        onChange={handleChange}
                        required
                    />
                    <span className="reg--err">&nbsp;&nbsp;&nbsp;{errMsgs.username}</span>
                    <br />
                    <br />
                    <label htmlFor="firstname">First name*</label>
                    <br />
                    <input
                        className="reg--input"
                        type="text"
                        name="firstname"
                        id="firstname"
                        value={fields.firstname}
                        onChange={handleChange}
                        required
                    />
                    <span className="reg--err">&nbsp;&nbsp;&nbsp;{errMsgs.firstname}</span>
                    <br />
                    <br />
                    <label htmlFor="lastname">Last name*</label>
                    <br />
                    <input
                        className="reg--input"
                        type="text"
                        name="lastname"
                        id="lastname"
                        value={fields.lastname}
                        onChange={handleChange}
                        required
                    />
                    <span className="reg--err">&nbsp;&nbsp;&nbsp;{errMsgs.lastname}</span>
                    <br />
                    <br />
                    <label htmlFor="email">Email*</label>
                    <br />
                    <input
                        className="reg--input"
                        type="email"
                        name="email"
                        id="email"
                        value={fields.email}
                        onChange={handleChange}
                        required
                    />
                    <span className="reg--err">&nbsp;&nbsp;&nbsp;{errMsgs.email}</span>
                    <br />
                    <br />
                    <label htmlFor="password1">Password*</label>
                    <br />
                    <input
                        className="reg--input"
                        type="password"
                        name="password1"
                        id="password1"
                        value={fields.password1}
                        onChange={handleChange}
                        required
                    />
                    <span className="reg--err">&nbsp;&nbsp;&nbsp;{errMsgs.password1}</span>
                    <br />
                    <br />
                    <label htmlFor="password2">Confirm password*</label>
                    <br />
                    <input
                        className="reg--input"
                        type="password"
                        name="password2"
                        id="password2"
                        value={fields.password2}
                        onChange={handleChange}
                        required
                    />
                    <span className="reg--err">&nbsp;&nbsp;&nbsp;{errMsgs.password2}</span>
                    <br />
                    <br />
                    <label htmlFor="institution">At which institution are you doing orchestration-related research?*</label>
                    <br />
                    <input
                        className="reg--input"
                        type="text"
                        name="institution"
                        id="institution"
                        value={fields.institution}
                        onChange={handleChange}
                        required
                    />
                    <span className="reg--err">&nbsp;&nbsp;&nbsp;{errMsgs.institution}</span>
                    <br />
                    <br />
                    <label htmlFor="position">What is your position at your institution?*</label>
                    <br />
                    <input
                        className="reg--input"
                        type="text"
                        name="position"
                        id="position"
                        value={fields.position}
                        onChange={handleChange}
                        required
                    />
                    <span className="reg--err">&nbsp;&nbsp;&nbsp;{errMsgs.position}</span>
                    <br />
                    <br />
                    <label htmlFor="interest">What is your interest in using OrchARD?*</label>
                    <br />
                    <textarea
                        className="reg--input"
                        name="interest"
                        id="interest"
                        rows="10"
                        cols="80"
                        value={fields.interest}
                        onChange={handleChange}
                        required
                    />
                    <span className="reg--err">&nbsp;&nbsp;&nbsp;{errMsgs.interest}</span>
                    <br />
                    <br />
                    <label htmlFor="reference">How did you hear about OrchARD?*</label>
                    <br />
                    <textarea
                        className="reg--input"
                        name="reference"
                        id="reference"
                        rows="10"
                        cols="80"
                        value={fields.reference}
                        onChange={handleChange}
                        required
                    />
                    <span className="reg--err">&nbsp;&nbsp;&nbsp;{errMsgs.reference}</span>
                    <br />
                    <br />
                </form>
                <Collapse in={alertFailureOpen}>
                    <Alert
                        variant="standard" severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setAlertFailureOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                            {failureMessage}
                    </Alert>
                </Collapse>
                <button
                    className="reg--submit"
                    onClick={handleSubmit}
                >
                    Register
                </button>
                <br />
                <span className="reg--footer">
                    <span>Already have an account? Log in&nbsp;</span>
                    <Link to="/login">here</Link>
                    <span>.</span>
                </span>
                </Paper>
            </div>
            
        </div>
    )
}