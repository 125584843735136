import React from 'react';

export default function ErrorList(props) {
    const {metaDataErrorsList, annotationErrorsList} = props;

    return (
        <div>
            {metaDataErrorsList.length > 0 && (
                <div>
                    <p><b>Meta Errors:</b></p>
                    {metaDataErrorsList.map((item, index) => (
                    <div key={index}>
                        <ul>
                            <li style={{ marginBottom: '0.25rem' }}>{item}</li>
                        </ul>
                    </div>
                    ))}
                </div>
            )}
            {annotationErrorsList.length > 0 && (
            <div>
                <br />
                <p><b>Annotation Errors:</b></p>
                {annotationErrorsList.map((item, index) => (
                <div key={index}>
                    <p style={{ marginBottom: '0.25rem' }}>{item.title}</p>
                    <ul>
                    {item.errorlist.map((error, errorIndex) => (
                        <li key={errorIndex} style={{ marginBottom: '0.25rem', color: 'red' }}>{error}</li>
                    ))}
                    </ul>
                </div>
                ))}
            </div>
            )}
        </div>    
    );
}