import React, { useRef, useState } from "react"
import "./Feedback.css"
import ReCAPTCHA from "react-google-recaptcha"
import axios from "axios";
import env from '../../env/env';
import MuiAlert from "@mui/material/Alert"
import { useNavigate } from "react-router-dom";
import { Paper } from '@mui/material';


export default function Feedback() {

    const captchaRef = useRef(null);
    const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(undefined);
    const navigate = useNavigate();
    const [sentFeedback, setSentFeedback] = useState('sending');
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [feedback, setFeedback] = useState(
        {
            name: '',
            email: '',
            subject: '',
            content: ''
        }
    );

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
    });

    function handleChange(event) {
        setFeedback({...feedback, [event.target.name]: event.target.value})
    }

    function handleSubmit(e) {

        //capctcha handling
        e.preventDefault();
        if (!isRecaptchaVerified) {
            alert('Please complete the reCAPTCHA challenge.');
            return;
        }
        
        //Axios post
        axios.post(env.BACKEND_API_URL + '/api/feedback/', feedback)
            .then((res) => {
                setSentFeedback( prev => 'successful');
                setFeedback({ name: '', email: '', subject: '', content: '' })
                captchaRef.current.reset();
            })
            .catch((error) => {
                console.error('Error:', error.message);
                setSentFeedback( prev => 'failure');
        
                if (error.response && error.response.status === 401) {
                    navigate('./login'); 
                }
            });
    }

    // Called when user passes the captcha test
    function handleRecaptchaVerify() {
        setIsRecaptchaVerified(true);
    };

    function handleCloseSnackBar(event, reason){
        setSentFeedback( prev => 'sending')
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    return (
        <div className="feedback--container">
            <header className="feedback--header">
                <h1>Feedback</h1>
            </header>
            <div className="feedback--content">
                <Paper elevation={3} style={{ padding: '20px', backgroundColor: 'white' }}>
                    <form
                        className="feedback--form"
                        onSubmit={handleSubmit}
                    >
                        <label htmlFor="name">Contact name</label>
                        <br />
                        <input
                            className="feedback--input"
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Your name"
                            onChange={handleChange}
                            required
                        />
                        <br />
                        <br />
                        <label htmlFor="email">Contact email</label>
                        <br />
                        <input
                            className="feedback--input"
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Your email (not required)"
                            onChange={handleChange}
                        />
                        <br />
                        <br />
                        <label htmlFor="subject">Subject</label>
                        <br />
                        <input
                            className="feedback--input"
                            type="text"
                            name="subject"
                            id="subject"
                            placeholder="eg. QueryBuilder"
                            onChange={handleChange}
                            required
                        />
                        <br />
                        <br />
                        <label htmlFor="content">Feedback</label>
                        <br />
                        <textarea
                            className="feedback--input"
                            name="content"
                            id="content"
                            placeholder="Your feedback..."
                            rows="10"
                            cols="80"
                            onChange={handleChange}
                            required
                        />
                        <br />
                        <br />
                        <ReCAPTCHA
                            sitekey={env.RECAPTCHA_KEY}
                            onChange={handleRecaptchaVerify}
                            ref={captchaRef}
                        />
                        {sentFeedback === 'failure' && <Alert severity="error" className="feedback--alert">
                            Sorry, we could not handle your feedback submission. Try again soon!</Alert>}
                        {sentFeedback === 'successful' && <Alert severity="success" open={openSnackBar}  
                            onClose={handleCloseSnackBar} className="feedback--alert">
                            Your feedback has been delivered. Thank you for your time!</Alert>}
                        <br />
                        <input
                            className="feedback--submit"
                            type="submit"
                            value="Send feedback"
                        />
                    </form>
                </Paper>
            </div>
        </div>
    )
}